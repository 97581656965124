import { object } from 'yup';
import ShortKYCCompanyClientSchema from "./ShortKYCCompanyClientSchema";
import dropdownOptions from '../util/dropdownOptions';
import {
    clientTypeId,
    preferredBranchId,
    boolYesNoType,
    strYesNoNAType,
    tipoIdentificacionComercial,
    financialCertBusinessSchema,
    leadChannel,
    contractSigner
} from './commons';

const form = {
    title: "Full KYC",
    displayName: 'FullKYCBusinessClient',
    page: 'full-kyc-business',
    commentFieldsMap: {
        "LoanComment": "caseLoanInfo.comments",
    },
    generateDocuments: "generateFullKYCDocumentsBusiness"
};

const declaracionJuradaSchema = {
    title: "Declaración Jurada",
    type: "object",
    properties: {
        "declaracionJurada1": {
            "title": "Que las informaciones contenidas en este formulario respecto de su perfil, origen de fondos e ingresos mensuales, son veraces, certeras y comprobables, incluyendo pero no limitado a las referentes a su empleo y/o profesión, según aplique, para cuyas actividades o labores, se encuentra debidamente habilitado o autorizado, si aplica.",
            "type": "boolean",
        },
        "declaracionJurada2": {
            "title": "Que reconoce que la Entidad es un sujeto obligado bajo las leyes, normas y regulaciones aplicables para la prevención de lavado de activos provenientes de actividades ilícitas, por lo que el Cliente autoriza a que la Entidad ejecute todos los pasos tendentes al cumplimiento de la debida diligencia, comprometiéndose el Cliente a cooperar para el buen desenvolvimiento de dicho proceso. A tales fines, el cliente autoriza a la Entidad realizar cualquier verificación en cualquier base de dato pública o privada. Específicamente, autoriza a la Entidad a verificar la información crediticia del Cliente a través de los centros de información crediticia, así como a suministrar a dichos centros de información crediticia, la información patrimonial y extra patrimonial que la Entidad considere relevante para los fines de evaluación crediticia, de conformidad con la Ley Organiza sobre Protección de Datos de Carácter Personal No. 172-13 del 13 de diciembre de 2013 y cualquier regulación aplicable.",
            "type": "boolean",
        },
        "declaracionJurada3": {
            "title": "Que los recursos, patrimonio y activos que posee y que serán utilizados en las operaciones de mercado de valores con la Entidad provienen de actividades lícitas y son destinados a actividades de lícito comercio, y no son el resultado de ninguna violación o contravención a las leyes vigentes en el país o en el extranjero. Igualmente, las rentas o frutos sean obtenidos a través de las operaciones de mercado de valores a través o con la intermediación de la Entidad serán destinados a un fin lícito, y no serán destinados a ninguna actividad que constituya una infracción a las leyes vigentes en el país o en el extranjero.",
            "type": "boolean",
        },
        "declaracionJurada4": {
            "title": "Que autoriza a la Entidad a crear una base de datos con mi información personal, bien sea la suministrada por el Cliente o bien sea la recolectada en los procesos de debida diligencia, y a compartir la misma con sus empresas vinculadas, así como a obtener toda la información y documentación que figuren en los expedientes del Cliente en empresas vinculadas a la Entidad; específicamente autorizo a la Entidad y empresas vinculadas a utilizar esta información para fines mercadológicos, publicitario y de ventas, incluyendo pero no limitado para la remisión correos electrónicos y mensajes telefónicos. El Cliente reconoce que la Entidad ha revelado que la información en la base de datos se encuentra sujeta al derecho de rectificación en los términos de la Ley Organiza sobre Protección de Datos de Carácter Personal No. 172-13 del 13 de diciembre de 2013 y cualquier regulación aplicable.",
            "type": "boolean",
        },
    },
    required: ["declaracionJurada1", "declaracionJurada2",
        "declaracionJurada3", "declaracionJurada4",
    ],
}

const garantiaSolidariaSchema = {
    title: " ",
    type: "object",
    properties: {
        "garantiaSolidaria": {
            title: "¿Incluye garantía solidaria?",
            ...boolYesNoType
        },
        "descGarantias": {
            title: "Descripción de garantía",
            type: "object",
            properties: {
                "noDeGarantia": {
                    title: "No. de Garantía",
                    type: "string",
                },
                "noFacilidadAsociada":
                {
                    title: "No. Facilidad asociada",
                    type: "string"
                },
                "tipoDeGarantia": {
                    title: " ",
                    description: "Tipo de Garantía",
                    type: "array",
                    items: {
                        type: "number",
                        oneOf: [
                            { title: "Garantía Solidaria", const: 1 },
                            { title: "Descuento de Nómina", const: 2 },
                            { title: "Hipotecario", const: 3 },
                            { title: "Prendaria", const: 4 },
                            { title: "Certificado Financiero", const: 5 },
                            { title: "Vehículo", const: 6 },
                            { title: "Otra", const: 7 },
                        ],
                    },
                    minItems: 1,
                    uniqueItems: true,
                    
                },
                "descGarantia":
                {
                    title: "Descripción de garantía",
                    type: "string",
                },
                "valGarantia":
                {
                    title: "Valor de Garantía en Tasación",
                    type: "string",
                },
            },
            dependencies: {
                "tipoDeGarantia": {
                    allOf: [
                        {
                            if: {
                                properties: { "tipoDeGarantia":{ contains: { const: 7 } }},
                            },
                            then: {
                                properties :{
                                    "contratoElaboradoPor": contractSigner
                                },
                                required:["contratoElaboradoPor"]
                            }
                        }
                    ]
                },
            }
        },
    }
}

const operacionesFinancierasNegociadoSchema = {
    title: " ",
    description: "Operaciones financieras que ha negociado",
    type: "object",
    properties: {
        "titles": {
            title: " ",
            type: "object",
            properties: {
                col1: { title: "Años manejando el producto", type: "null" },
                col2: { title: "Número de operaciones anuales", type: "null" },
                col3: { title: "Nivel de conocimiento", type: "null" },
            },
        },
        "inversionCertificadosFinancieros": {
            title: " ",
            type: "object",
            properties: {
                "title": {
                    title: "Inversión en Certificados Financieros",
                    type: "null",
                },
                "anosManejandoProducto": {
                    title: " ",
                    type: "string"
                },
                "numOperacionesAnuales": {
                    title: " ",
                    type: "string"
                },
                "nivelConocimiento": {
                    title: " ",
                    type: "string",
                    enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                }
            },
        },
        "bonosSoberanos": {
            title: " ",
            type: "object",
            properties: {
                "title": {
                    title: "Bonos soberanos y libres de riesgo",
                    type: "null",
                },
                "anosManejandoProducto": {
                    title: " ",
                    type: "string"
                },
                "numOperacionesAnuales": {
                    title: " ",
                    type: "string"
                },
                "nivelConocimiento": {
                    title: " ",
                    type: "string",
                    enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                }
            },
        },
        "bonosCorporativos": {
            title: "",
            type: "object",
            properties: {
                "title": {
                    title: "Bonos Corporativos",
                    type: "null",
                },
                "anosManejandoProducto": {
                    title: " ",
                    type: "string"
                },
                "numOperacionesAnuales": {
                    title: " ",
                    type: "string"
                },
                "nivelConocimiento": {
                    title: " ",
                    type: "string",
                    enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                }
            },
        },
        "fondosInversionesMutuos": {
            title: " ",
            type: "object",
            properties: {
                "title": {
                    title: "Fondos de inversiones mutuos, opciones y futuros",
                    type: "null",
                },
                "anosManejandoProducto": {
                    title: " ",
                    type: "string"
                },
                "numOperacionesAnuales": {
                    title: " ",
                    type: "string"
                },
                "nivelConocimiento": {
                    title: " ",
                    type: "string",
                    enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                }
            },
        },
        "fondosCerrados": {
            title: " ",
            type: "object",
            properties: {
                "title": {
                    title: "Fondos Cerrados, Préstamos de Margen, Operaciones de Préstamos de Títulos Valores, Compra-Venta de Operaciones a Plazo (Forwards) y Acciones",
                    type: "null",
                },
                "anosManejandoProducto": {
                    title: " ",
                    type: "string"
                },
                "numOperacionesAnuales": {
                    title: " ",
                    type: "string"
                },
                "nivelConocimiento": {
                    title: " ",
                    type: "string",
                    enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                }
            },
        },
        "otrosDerivados": {
            title: " ",
            type: "object",
            properties: {
                "title": {
                    title: "Otros derivados",
                    type: "null",
                },
                "indicar": {
                    title: " ",
                    type: "string"
                },
                "anosManejandoProducto": {
                    title: " ",
                    type: "string"
                },
                "numOperacionesAnuales": {
                    title: " ",
                    type: "string"
                },
                "nivelConocimiento": {
                    title: " ",
                    type: "string",
                    enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                }
            },
        },
    },
}

const datosEmpresaSchema = {
    title: "Datos generales de la empresa",
    type: "object",
    properties: {
        "fechaConst": {
            title: "Fecha de Constitución",
            type: "string",
            format: "date"
        },
        "lugarConst": {
            title: "Lugar de Constitución",
            type: "string",
        },
        "dirResidencia": {
            title: "Dirección de residencia",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "zonaGeograficaNacional": {
            title: "Zona Geográfica Nacional",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "numEmpleados": {
            title: "No. De Empleados:",
            type: "number",
        },
        "promMensVentas": {
            title: "Promedio Mensual de Ventas:",
            type: "number",
        },
        "prodServOfrecen": {
            title: "Productos y/o Servicios que Ofrecen:",
            type: "string",
        },
        // "inversionistaInstitucional": { title: "¿Es un inversionista institucional?", ...boolYesNoType },
        "website": {
            title: "Website",
            type: "string",
        },
        "sujetoObligadoLey155": { title: "¿Es Sujeto Obligado de la Ley 155-17?", ...boolYesNoType },
        "empresasAfiliadas": {
            title: " ",
            description: "Empresas Afiliadas, Subsidiarias y Cías. Relacionadas al Negocio y/o Propietarios",
            type: "array",
            minItems: 1,
            maxItems: 8,
            items: {
                type: "object",
                properties: {
                    "nombreRazonSocial": {
                        title: "Nombre o Razón Social",
                        type: "string",
                    },
                    "rncNumber": {
                        title: "RNC No.",
                        type: "string",
                    }
                }
            }
        },
        "principalesClientes": {
            type: "array",
            title: " ",
            description: " Principales Clientes",
            minItems: 1,
            maxItems: 4,
            items: {
                type: "object",
                properties: {
                    "principalesClientesField": {
                        title: "Nombre",
                        type: "string",
                    },
                }
            }
        },
        "principalesProveedores": {
            type: "array",
            title: " ",
            description: " Principales Proveedores",
            minItems: 1,
            maxItems: 4,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                }
            }
        },
        "principalesAccionistas": {
            title: " ",
            description: "Principales Accionistas",
            type: "object",
            properties: {
                "asteriskNote": {
                    title: "*En caso de que un accionista con un 10% o más de participación sea a su vez una persona jurídica deberá indicarse su Beneficio Final (persona física)",
                    type: "null",
                },
                "princAccionList": {
                    title: " ",
                    type: "array",
                    minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            "nombreCompleto": {
                                title: "Nombre Completo",
                                type: "string",
                            },
                            "participacionAccionaria": {
                                title: "Participación Accionaria",
                                type: "string",
                            },
                            "nacionalidad": {
                                title: "Nacionalidad:",
                                type: "string",
                                enum: dropdownOptions.pais,
                            },
                            "ciudadaniaUSA": {
                                title: "Ciudadanía o Residencia de Estados Unidos",
                                ...boolYesNoType
                            },
                            "numeroIdent": {
                                title: "No. de Identificación",
                                type: "string",
                            },
                            "benefFinal": {
                                title: "Beneficiario Final",
                                type: "string",
                            },
                        }
                    }
                }
            }
        },
        "miembrosOrganoDireccion": {
            title: " ",
            description: "Miembros del Órgano de Dirección",
            type: "array",
            minItems: 1,
            maxItems: 5,
            items: {
                type: "object",
                properties: {
                    "nombreCompleto": {
                        title: "Nombre Completo",
                        type: "string",
                    },
                    "posicion": {
                        title: "Posición",
                        type: "string",
                    },
                    "nacionalidad": {
                        title: "Nacionalidad:",
                        type: "string",
                        enum: dropdownOptions.pais,
                    },
                    "numeroIdent": {
                        title: "No. de Identificación",
                        type: "string",
                    },
                }
            }
        },
        "nombreDirectores": {
            title: "Indicar los nombres de los Directores autorizados a asignar representantes legales a manejar cuentas bancarias en nombre de la empresa",
            type: "string",
        },
    },
    required: ["fechaConst", "lugarConst", "dirResidencia", "sector", "ciudad", "pais", "zonaGeograficaNacional"]
}

const personaPoliticamenteExpuestaSchema = {
    title: "Personas Políticamente Expuestas",
    description: "Socios, accionistas, miembros de la junta de directores o representantes legales que sean o hayan sido PEP",
    type: "array",
    minItems: 1,
    maxItems: 4,
    items: {
        type: "object",
        properties: {
            "nombreCompleto": {
                title: "Nombre Completo",
                type: "string",
            },
            "cargo": {
                title: "Cargo",
                type: "string",
            },
            "fechaDesignacion": {
                title: "Fecha de Designación",
                type: "string",
                format: "date"
            },
            "fechaRemocion": {
                title: "Fecha de Remoción",
                type: "string",
                format: "date"
            },
            "pais": {
                title: "País",
                type: "string",
                enum: dropdownOptions.pais,
            }
        }
    }
}

const otraInfoSchema = {
    title: "Otra información relevante",
    type: "object",
    properties: {
        "cantOperaciones": {
            title: "Cantidad de Operaciones esperadas",
            type: "string",
            enumNames: ["De 0 a 5", "De 5 a 10", "Más de 10", "Otro"],
            enum: ["De 0 a 5", "De 5 a 10", "Mas de 10", "Otro"],
        },
        "formaTransacciones": {
            title: " ",
            description: "Forma de las transacciones",
            type: "object",
            properties: {
                "transacciones": {
                    title: " ",
                    type: "object",
                    properties: {
                        "efectivo": {
                            title: "Efectivo",
                            type: "boolean",
                        },
                        "cheque": {
                            title: "Cheque",
                            type: "boolean",
                        },
                        "transferencias": {
                            title: "Transferencias",
                            type: "boolean",
                        },
                        "internacionales": {
                            title: "Internacionales",
                            type: "boolean",
                        },
                        "nacionales": {
                            title: "Nacionales",
                            type: "boolean",
                        },
                    },
                },
            }
        },
        "beneficiariosDeTransaccionDiferentes": {
            title: "¿Existen beneficiarios directos o indirectos de la transaccion diferentes de usted?",
            ...boolYesNoType
        },
        "identBeneficiarioFinal": {
            title: "Identificación de beneficiario final de la operación (si aplica)",
            type: "string"
        },
        "declaracionPersRel": {
            title: "Declaración de personas relacionadas",
            type: "string"
        },
        "otraInfo": {
            title: "Cualquier otra información relevante (si aplica)",
            type: "string"
        },
        "vinculadoJMMB": {
            title: "¿Es vinculado o relacionado a un vinculado de JMMB?",
            ...boolYesNoType
        },
    },
    required: ["beneficiariosDeTransaccionDiferentes", "vinculadoJMMB"],
    dependencies: {
        "cantOperaciones": {
            oneOf: [
                {
                    properties: {
                        "cantOperaciones": {
                            const: "Otro",
                        },
                        "otroOperaciones": {
                            title: "Otro",
                            type: "string"
                        }
                    },
                },
                {
                    properties: {
                        "cantOperaciones": {
                            const: "De 0 a 5",
                        },
                    }
                },
                {
                    properties: {
                        "cantOperaciones": {
                            const: "De 5 a 10",
                        },
                    }
                },
                {
                    properties: {
                        "cantOperaciones": {
                            const: "Mas de 10",
                        },
                    }
                }
            ],
        },
        "vinculadoJMMB": {
            oneOf: [
                {
                    properties: {
                        "vinculadoJMMB": {
                            const: true
                        },
                        "empresaVinculada": {
                            title: "Empresa vinculada",
                            type: "string"
                        }
                    }
                },
                {
                    properties: {
                        "vinculadoJMMB": {
                            const: false
                        },
                    }
                }
            ]
        },
        "beneficiariosDeTransaccionDiferentes": {
            oneOf: [
                {
                    properties: {
                        "beneficiariosDeTransaccionDiferentes": {
                            const: true
                        },
                        "nombreBeneficiario": {
                            title: "Nombre de beneficiario",
                            type: "string"
                        }
                    }
                },
                {
                    properties: {
                        "beneficiariosDeTransaccionDiferentes": {
                            const: false
                        },
                    }
                }
            ]
        },
    },
}

const perfilInversionistaSchema = {
    title: "Perfil del Inversionista",
    type: "object",
    properties: {
        "riskProdServTitle": {
            title: "Riesgo de productos y servicios",
            type: "null",
        },
        "riesgoProductosServiciosEdad": {
            title: "Antiguedad de la Empresa",
            type: "string",
            enumNames: ["Menos de 1 año", "Entre 1 y 3 años", "Entre 3 y 5 años", "Mas de 5 años"],
            enum: ["Menos de 1 ano", "Entre 1 y 3 anos", "Entre 3 y 5 anos", "Mas de 5 anos"],
        },
        "riesgoProductosServiciosObjetivo": {
            title: "Objetivo principal de su capital de inversión",
            type: "string",
            enumNames: ["Rentabilidad", "Seguridad", "Apreciación de Capital"],
            enum: ["Rentabilidad", "Seguridad", "Apreciacion de Capital"],
        },
        "criteriosIdeasRentabilidad": {
            title: "Criterio que se ajusta mejor a sus ideas de rentabilidad/riesgo",
            type: "string",
            enum: ["Conservador (bajo riesgo)", "Moderado (riesgo medio)", "Agresivo (alto riesgo)"]
        },
        "operacionesFinancierasNegociado": operacionesFinancierasNegociadoSchema,
        "rentabilidadMinima": {
            title: "Rentabilidad mínima deseada",
            type: "string",
            enumNames: ["Mínimo mantener el capital", "Rentabilidad no menor a inflación", "Rentabilidad superior a la inflación"],
            enum: ["Minimo mantener el capital", "Rentabilidad no menor a inflacion", "Rentabilidad superior a la inflacion"],
        },
        "plazoDeseaCumplirObjetivos": {
            title: "Plazo en que desea cumplir sus objetivos",
            type: "string",
            enumNames: ["Menos de 1 año", "Entre 1 año y 3 años", "Más de 3 años"],
            enum: ["Menos de 1 ano", "Entre 1 ano y 3 anos", "Mas de 3 anos"],
        },
        "plazoAnticipaNecesitarInversiones": {
            title: "Plazo en que anticipa podría necesitar parcial o totalmente sus inversiones",
            type: "string",
            enumNames: ["Menos de 1 mes", "De 2 a 3 meses", "De 4 a 6 meses", "De 7 a 12 meses", "De 1 a 2 años", "De 3 a 5 años"],
            enum: ["Menos de 1 mes", "De 2 a 3 meses", "De 4 a 6 meses", "De 7 a 12 meses", "De 1 a 2 anos", "De 3 a 5 anos"],
        },
        "capacidadAbsorverPerdidas": {
            title: "Capacidad para absorber pérdidas",
            type: "string",
            enum: ["0%", "De 1% a 15%", "Mayor a 15%"]
        },
    },
}

const situacionFinancieraSchema = {
    title: "Situación Financiera",
    description: "Estado de Situación",
    type: "object",
    properties: {
        "ingresosAnuales": {
            title: "Ingresos Anuales",
            type: "string",
            enumNames: ["Menos de RD$1.0 Millón", "Entre RD$1.0 y RD$3.0 Millones", "Entre RD$3.0 y RD$5.0 Millones", "Más de RD$5.0 Millones"],
            enum: ["Menos de RD$1.0 Millon", "Entre RD$1.0 y RD$3.0 Millones", "Entre RD$3.0 y RD$5.0 Millones", "Mas de RD$5.0 Millones"],

        },
        "capacidadAhorro": {
            title: "% Capacidad de ahorro",
            type: "number",
        },
        "totalActivosLiquidos": {
            title: "Total Activos Líquidos",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$5.7 Millones", "Entre RD$7.0 y RD$20.0 Millones", "Más de RD$50.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$5.7 Millones", "Entre RD$7.0 y RD$20.0 Millones", "Mas de RD$50.0 Millones"],
        },
        "porcientoCuentasAhorroOCorriente": {
            title: "% en Cuentas Ahorro o Corriente",
            type: "number",
        },
        "porcientoOtro": {
            title: "% en Otro",
            type: "number",
        },
        "patrimonioTotal": {
            title: "Patrimonio Total",
            type: "string",
            enumNames: ["Menos de RD$3.0 Millones", "Entre RD$3.0 y RD$10.0 Millones", "Más de RD$10.0 Millones"],
            enum: ["Menos de RD$3.0 Millones", "Entre RD$3.0 y RD$10.0 Millones", "Mas de RD$10.0 Millones"],
        },
        "porcientoDeudas": {
            title: "% Deudas",
            type: "number",
        },
        "referenciasBancarias": {
            type: "array",
            title: " ",
            description: "Referencias Bancarias",
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "banco": {
                        title: "Banco",
                        type: "string",
                    },
                    "tipoCuenta": {
                        title: "Tipo de Cuenta",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                        format: "phone-rd",

                    },
                    "oficial": {
                        title: "Oficial",
                        type: "string",
                    },
                },
            },
            required: [],
        },
        "referenciasComerciales": {
            type: "array",
            title: " ",
            description: "Referencias Comerciales",
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                        format: "phone-rd",
                    },
                    "direccion": {
                        title: "Dirección",
                        type: "string",
                    },
                },
            },
            required: [],
        },

    },
    required: ["ingresosAnuales", "totalActivosLiquidos", "patrimonioTotal"],
}

const analisisPatrimonioSchema = {
    title: "Análisis Patrimonio",
    description: "Cambios de los activos y pasivos registrados en los últimos 2 años",
    type: "object",
    properties: {
        "PeriodosFiscales1": {
            title: " ",
            type: "object",
            properties: {
                "anosPeriodosFiscales1": {
                    title: "Años o Periodos Fiscales",
                    type: "number"
                },
                "totalPatrimonio1": {
                    title: "Total Patrimonio RD$",
                    type: "number"
                },
                "totalActivosLiquidos1": {
                    title: "Total Activos Líquidos RD$",
                    type: "number"
                },
                "resultadoAnalisisPatrimonio1": {
                    title: "Resultado",
                    type: "string",
                    enum: ["No cambios significativos", "Cambios significativos (comentar)"]
                },
            }
        },
        "PeriodosFiscales2": {
            title: " ",
            type: "object",
            properties: {
                "anosPeriodosFiscales2": {
                    title: "Años o Periodos Fiscales",
                    type: "number"
                },
                "totalPatrimonio2": {
                    title: "Total Patrimonio RD$",
                    type: "number"
                },
                "totalActivosLiquidos2": {
                    title: "Total Activos Líquidos RD$",
                    type: "number"
                },
                "resultadoAnalisisPatrimonio2": {
                    title: "Resultado",
                    type: "string",
                    enum: ["No cambios significativos", "Cambios significativos (comentar)"]

                },
            }

        },
    },
}

const resultadoPerfilSchema = {
    title: "Resultado del Perfil",
    type: "object",
    properties: {
        "relacionCaraCara": {
            title: "Relación Cara a Cara",
            ...boolYesNoType
        },
        "fechaActualizacionExpediente": {
            title: "Fecha de Actualización de Expediente",
            type: "string",
            format: "date"
        },
        "indicarInformacionActualizadaYFuente": {
            title: "Indicar información actualizada y fuente de la misma",
            type: "string",
        },
        "resumenCliente": {
            title: "Resumen del cliente:",
            type: "string",
        },
        "productoSolicitadoAjustaCliente": {
            title: "¿Se ajusta el producto solicitado/ofrecido a las características y objetivos del cliente?",
            ...boolYesNoType
        },
        "capacidadPatrimonial": {
            title: "1. ¿La capacidad patrimonial del cliente/prospecto en Activos en efectivo o inversiones en instrumentos financieros es igual o mayor a RD$50,000,000.00 o su equivalente en cualquier otra moneda, depositadas o invertidas en el Sistema Financiero Nacional o extranjero?",
            ...strYesNoNAType
        },
        "clienteVinculado": {
            title: "2. ¿El cliente, fue vinculado a JMMB Puesto de Bolsa con anterioridad a 1 año y, ha mostrado experiencia mediante la realización de Operaciones en el Mercado de Valores Nacional o Internacional con un volumen de al menos RD$ 10,000,000 o su equivalente en cualquier otra moneda, por año, con frecuencia media de 10 operaciones por trimestre, en el último año?",
            ...strYesNoNAType
        },
        "clienteProfesional": {
            title: "El cliente/prospecto es",
            type: "string",
            enum: ["Profesional", "No Profesional"],
        },
        "tolerancia": {
            title: "Tolerancia",
            type: "string",
            enum: ["Conservador", "Moderado", "Agresivo"]
        },
        "acuerdoConPerfilObtenido": {
            title: "¿Está usted de acuerdo con el Perfil obtenido en la evaluación?",
            ...boolYesNoType
        },
    },
    required: ["relacionCaraCara", "productoSolicitadoAjustaCliente", "acuerdoConPerfilObtenido", "clienteProfesional", "tolerancia"],
    dependencies: {
        "relacionCaraCara": {
            oneOf: [
                {
                    properties: {
                        "relacionCaraCara": {
                            const: false,
                        },
                        "canalNoCaraCara": {
                            title: "Canal No cara a cara",
                            type: "string",
                            enum: ["Correo Electrónico", "Teléfono", "Videoconferencia", "Otro"]
                        }
                    },
                    dependencies: {
                        "canalNoCaraCara": {
                            oneOf: [
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Otro"
                                        },
                                        "otroCanalNoCaraCara": {
                                            title: "Si es Otro (Indique)",
                                            type: "string"
                                        }
                                    }
                                },
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Correo Electrónico"
                                        },
                                    }
                                },
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Teléfono"
                                        },
                                    }
                                },
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Videoconferencia"
                                        },
                                    }
                                }
                            ]
                        }
                    }
                },
                {
                    properties: {
                        "relacionCaraCara": {
                            const: true,
                        },
                    },
                }
            ],
        },
        "clienteProfesional": {
            oneOf: [
                {
                    properties: {
                        "clienteProfesional": {
                            const: "Profesional"
                        },
                        "consideradoNoProfesional": {
                            title: "Cliente desea ser considerado No Profesional",
                            type: "boolean"
                        }
                    }
                },
                {
                    properties: {
                        "clienteProfesional": {
                            const: "No Profesional"
                        },
                    }
                }
            ]
        }
    },
}

const firmantesAdicionalesSchema = {
    title: "Firmantes adicionales al Representante Legal",
    type: "object",
    properties: {
        "nombreCorredor": {
            title: "Nombre del Corredor",
            type: "string"
        },
        "numeroCorredor": {
            title: "Número del Corredor",
            type: "number"
        },
        "firmantes": {
            title: " ",
            type: "array",
            minItems: 1,
            items: {
                type: "object",
                properties: {
                    "firmante": {
                        title: "Firmante",
                        type: "string",
                    },
                }
            }
        },
    }
}

const solicitudPrestamoSchema = {
    title: "Solicitud de Préstamo",
    type: "object",
    properties: {
        "montoDeseadoPrestamo": {
            title: "Monto deseado de préstamo",
            type: "number",
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
        "destinoFondos": {
            title: "Destino de los fondos",
            type: "string",
        },
        "propLocalEmpresa": {
            title: "Propiedad del Local de empresa",
            type: "string",
            enum: ["Propio", "Financiado", "Alquilado", "Familiar"],
        },
        "proposito": {
            title: "Propósito",
            type: "string"
        },
        "descFacilidades": {
            title: " ",
            description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
            type: "array",
            maxItems: 5,
            items: {
                type: "object",
                properties: {
                    "numFacilidad": {
                        title: "No. de Facilidad",
                        type: "string",
                    },
                    "descripcion": {
                        title: "Descripción",
                        type: "string",
                    },
                    "tipoFacilidad": {
                        title: "Tipo de Facilidad",
                        type: "string",
                        enumNames: [
                            "Consumo",
                            "Comercial- Micro",
                            "Descuento nómina",
                            "Hipotecario",
                        ],
                        enum: [
                            "Consumo",
                            "Comercial- Micro",
                            "Descuento nomina",
                            "Hipotecario",
                        ],
                    },
                    "montoAprobado": {
                        title: "Monto Aprobado (si aplica)",
                        type: "string",
                    },
                    "montoUtilizado": {
                        title: "Monto Utilizado (si aplica)",
                        type: "string",
                    },
                    "montoPropuesto": {
                        title: "Monto Propuesto",
                        type: "string",
                    },
                    "tasa": {
                        title: "Tasa",
                        type: "number",
                    },
                    "formaPago": {
                        title: "Forma de Pago",
                        type: "string",
                        enumNames: [
                            "Ingresos por negocio",
                            "Ingresos por salario",
                            "Intereses generados",
                            "Otro",
                        ],
                        enum: [
                            "Ingresos por negocio",
                            "Ingresos por salario",
                            "Intereses generados",
                            "Otro",
                        ],
                    },
                    "cuotaEstimada": {
                        title: "Cuota Estimada",
                        type: "string",
                    },
                    "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
                },
            },
        },
        "incluyeGarantia": {
            title: " ",
            type: "object",
            properties: {
                "garantiaSelection": {
                    title: "¿Incluye garantía?",
                    ...boolYesNoType
                },
            },
            dependencies: {
                "garantiaSelection": {
                    oneOf: [
                        {
                            properties: {
                                "garantiaSelection": {
                                    const: true,
                                },
                                "comentarios": {
                                    title: "Comentarios o Instrucciones Especiales",
                                    type: "string"
                                },
                                "garantiaSolidariaSchema": garantiaSolidariaSchema
                            },
                        },
                        {
                            properties: {
                                "garantiaSelection": {
                                    const: false,
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
   // required: ["tasaCambio"]
}

const safiSchema = {
    title: "Inversiones en SAFI",
    description: "",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión SAFI en USD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para SAFI en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión SAFI en RD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para SAFI en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const informeVisitaSchema = {
    title: "Informe de Visita",
    type: "object",
    properties: {
        "fechaHora": {
            title: "Fecha y hora de visita",
            type: "string",
            format: "datetime",
        },
        "motivoDeVisita": {
            title: "Motivo de la visita",
            type: "string",
            enumNames: ["Establecer Negociación", "Seguimiento al cliente", "Actualización de dirección", "Otro"],
            enum: ["Establecer Negociacion", "Seguimiento al cliente", "Actualizacion de direccion", "Otro"],
        },
        "participantesReunion": {
            type: "array",
            title: " ",
            description: "Participantes de la reunión",
            minItems: 1,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre Completo",
                        type: "string",
                    },
                    "cargo": {
                        title: "Cargo",
                        type: "string",
                    },
                }
            }
        },
        "breveDescEntorno": {
            title: "Breve descripción del entorno y la historia de la empresa (incluye riesgo del sector o la ubicación, organización de la empresa)",
            type: "string",
        },
        "breveAnalisis": {
            title: "Breve análisis del perfil del negocio (productos, servicios y otros factores que el ejecutivo considere)",
            type: "string",
        },
        "brevDescInst": {
            title: "Breve descripción de las instalaciones físicas (condiciones físicas, condición de propiedad del local)",
            type: "string",
        },
        "principalesClientes": {
            type: "array",
            title: " ",
            description: "Clientes Principales",
            minItems: 1,
            items: {
                type: "object",
                properties: {
                    "principalCliente": {
                        title: "Cliente principal",
                        type: "string",
                    },
                    "terminosVenta": {
                        title: "Términos de venta",
                        type: "string",
                    },
                }
            }
        },
        "principalesProveedores": {
            type: "array",
            title: " ",
            minItems: 1,
            description: "Proveedores Principales",
            items: {
                type: "object",
                properties: {
                    "principalProveedor": {
                        title: "Proveedor principal",
                        type: "string",
                    },
                    "terminosCompra": {
                        title: "Términos de compra",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                }
            }
        },
        "principalesCompetidores": {
            type: "array",
            title: " ",
            minItems: 1,
            items: {
                type: "object",
                properties: {
                    "principalCompetidor": {
                        title: "Competidor principal",
                        type: "string",
                    },
                }
            }
        },
        "perspectivaCortoPlazo": {
            title: "Perspectivas a corto plazo de ventas y del mercado",
            type: "string",
        },
        "otrasObs": {
            title: "Otras observaciones del Ejecutivo",
            type: "string",
        },
    },
    dependencies: {
        "motivoDeVisita": {
            oneOf: [
                {
                    properties: {
                        "motivoDeVisita": {
                            const: "Establecer Negociacion",
                        },
                    },
                },
                {
                    properties: {
                        "motivoDeVisita": {
                            const: "Seguimiento al cliente",
                        },
                    },
                },
                {
                    properties: {
                        "motivoDeVisita": {
                            const: "Actualizacion de direccion",
                        },
                    },
                },
                {
                    properties: {
                        "motivoDeVisita": {
                            const: "Otro",
                        },
                        "otroMotivo": {
                            title: "Explicación",
                            type: "string",
                        },
                    },
                },
            ],
        }
    }

}

const matrizRiesgoSchema = {
    title: "Resumen guia para el llenado de Matriz de Riesgo",
    type: "null"
}

const soporteDebidaDiligenciaSchema = {
    title: "Soportes Debida Diligencia",
    type: "object",
    properties: {
        "informeVisita": {
            title: "Informe de visita",
            type: "number",
        },
        "revisionListaOFAC": {
            title: "Revisión en lista OFAC",
            type: "number",
        },
        "tieneOFAC": {
            title: "¿Tiene coincidencia en lista OFAC?",
            ...boolYesNoType
        },
        "revisionListaONU": {
            title: "Revisión en lista ONU",
            type: "number",
        },
        "tieneONU": {
            title: "¿Tiene coincidencia en lista ONU?",
            ...boolYesNoType
        },
        "matrizRiesgo": {
            title: "Matriz de riesgo",
            type: "number",
        },
        "nivelRiesgo": {
            title: "Nivel de riesgo",
            type: "number",
            enumNames: ["Alto", "Medio", "Bajo"],
            enum: [3, 2, 1]
        },
        "revisionBuroCredito": {
            title: "Revisión buró de Crédito",
            type: "number",
        },
    },
    required: ["revisionListaOFAC", "revisionBuroCredito",
        "revisionListaONU", "matrizRiesgo", "nivelRiesgo", "tieneOFAC", "tieneONU"
    ],
}

const infoRepLegal = {
    title: " ",
    description: "Representante Legal",
    type: "object",
    properties: {
        "nombreRep": {
            title: "Nombre Representante",
            type: "string",
        },
        "apellidoRep": {
            title: "Apellido Representante",
            type: "string"
        },
        "posRep": {
            title: "Posición Representante",
            type: "string"
        },
        "tipoId": {
            title: "Tipo Identificación",
            type: "string"
        },
        "numeroId": {
            title: "Número Identificación",
            type: "string"
        },
        "celularRep": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefonoRep": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "emailRep": {
            title: "Email",
            type: "string"
        }
    },
    required: []
}

const inversionesSchema = {
    title: "Inversiones en Puesto",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión Puesto en USD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para Puesto en USD",
            type: "string",
            enumNames: [
                "Ventas",
                "Tesorería",
                "Inversiones anteriores",
                "Otro",
            ],
            enum: [
                "Ventas",
                "Tesoreria",
                "Inversiones anteriores",
                "Otro",
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Puesto en RD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para Puesto en RD",
            type: "string",
            enumNames: [
                "Ventas",
                "Tesorería",
                "Inversiones anteriores",
                "Otro",
            ],
            enum: [
                "Ventas",
                "Tesoreria",
                "Inversiones anteriores",
                "Otro",
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Especifique",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Especifique",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const savingsAccountSchema = {
    title: " ",
    description: "Cuenta de Ahorro",
    type: "object",
    properties: {
        "rdCurrency": {
            title: "RD$",
            type: "boolean",
        },
        "usCurrency": {
            title: "US$",
            type: "boolean",
        }
    },
    dependencies:{
        "rdCurrency":{
            oneOf: [
                {
                    properties: {
                        "rdCurrency": {
                            const: true
                        },
                        "depositoEstimadoRD": {
                            title: "Monto estimado primer depósito en RD",
                            type: "number",
                        },
                        "origenFondosRD": {
                            title: "Origen de fondos en RD",
                            type: "string",
                            enumNames: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehículo",
                                "Liquidación de otra inversión",
                                "Otro",
                                "No aplica"
                            ],
                            enum: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehiculo",
                                "Liquidacion de otra inversion",
                                "Otro",
                                "No aplica"
                            ],
                        },
                    },
                    dependencies: {
                        "origenFondosRD": {
                            allOf: [
                                {
                                    if: {
                                        properties: {
                                            "origenFondosRD": {
                                                const: "Otro",
                                            },
                                        }
                                    },
                                    then: {
                                        properties: {
                                            "origenFondosRDOtro": {
                                                title: "Otro",
                                                type: "string"
                                            },
                                        },
                                        required: ["origenFondosRDOtro"]
                                    }
                                }
                            ]
                        },
                    },
                    required: ["depositoEstimadoRD", "origenFondosRD"]
                },
                {
                    properties: {
                        "rdCurrency": {
                            const: false
                        },
                    }
                }    
            ]
        },
        "usCurrency":{
            oneOf: [
                {
                    properties: {
                        "usCurrency": {
                            const: true
                        },
                        "depositoEstimadoUSD": {
                            title: "Monto estimado primer depósito en USD",
                            type: "number",
                        },
                        "origenFondosUSD": {
                            title: "Origen de fondos en USD",
                            type: "string",
                            enumNames: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehículo",
                                "Liquidación de otra inversión",
                                "Otro",
                                "No aplica"
                            ],
                            enum: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehiculo",
                                "Liquidacion de otra inversion",
                                "Otro",
                                "No aplica"
                            ],
                        },
                    },
                    dependencies: {
                        "origenFondosUSD": {
                            allOf: [
                                {
                                    if: {
                                        properties: {
                                            "origenFondosUSD": {
                                                const: "Otro",
                                            },
                                        }
                                    },
                                    then: {
                                        properties: {
                                            "origenFondosUSDOtro": {
                                                title: "Otro",
                                                type: "string"
                                            },
                                        },
                                        required: ["origenFondosUSDOtro"]
                                    }
                                }
                            ]
                        },
                    },
                    required: ["depositoEstimadoUSD", "origenFondosUSD"]
                },
                {
                    properties: {
                        "usCurrency": {
                            const: false
                        },
                    }
                }    
            ]
        }
    }
}

const kycCommercialClientSchema = {
    title: "Información de intención de vinculación",
    type: "object",
    properties: {
        "institucionVincularse": {
            title: "Institución a vincularse",
            type: "array",
            items: {
                type: "number",
                oneOf: [
                    { title: "Banco", const: 1 },
                    { title: "Puesto", const: 2 },
                    { title: "SAFI", const: 3 },
                ],
            },
            minItems: 1,
            uniqueItems: true,
        },
    },
    dependencies: {
        "institucionVincularse": {
            allOf: [
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 1 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosBanco": {
                                title: "Productos de interés Banco",
                                type: "array",
                                lookup: {
                                    api: 'products/1',
                                    params: {
                                        clientType: { field: 'clientTypeId' }
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                items: { type: "number" },
                                minItems: 1,
                                uniqueItems: true,
                            },
                        },
                        dependencies: {
                            "productosBanco": {
                                allOf: [
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 1 } } // "Cuenta de Ahorro"
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "cuentaAhorroBasica": { title: "¿Es Cuenta de Ahorro Básica?", ...boolYesNoType },
                                                "savingsAccountSchema": savingsAccountSchema,
                                            },
                                            dependencies: {
                                                "cuentaAhorroBasica": {
                                                    oneOf: [{
                                                        properties: {
                                                            "cuentaAhorroBasica": { const: true },
                                                            "soloMantieneEstaCuenta": { title: "¿Cliente solo mantiene esta cuenta?", ...boolYesNoType }
                                                        }
                                                    },
                                                    {
                                                        properties: {
                                                            "cuentaAhorroBasica": { const: false },
                                                        }
                                                    }]
                                                }
                                            }
                                        },
                                        else: {
                                            properties: {
                                                "excepcionCuentaAhorro": {
                                                    title: "Razón por la que no desea cuenta de ahorro:",
                                                    type: "string",
                                                    enum: ["Ya posee cuenta en JMMB", "No desea el producto"]
                                                },
                                            }
                                        }
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 17 } } // "Prestamo Comercial"
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                                "informeVisita": informeVisitaSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": {
                                                    contains: { const: 3 } // "Certificado Financiero"
                                                },
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "financialCertSchema": financialCertBusinessSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 7 } },  // Línea de Crédito #14 in dev db
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                                "informeVisita": informeVisitaSchema,
                                            },
                                        },
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 2 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosPuesto": {
                                title: "Productos de interés Puesto",
                                type: "array",
                                lookup: {
                                    api: 'products/2',
                                    params: {
                                        clientType: { field: 'clientTypeId' },
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                minItems: 1,
                                items: { type: "number" },
                                uniqueItems: true,
                            },
                            "inversionesSchema": inversionesSchema
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 3 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosSAFI": {
                                title: "Productos de interés SAFI",
                                type: "array",
                                lookup: {
                                    api: 'products/3',
                                    params: {
                                        clientType: { field: 'clientTypeId' },
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                minItems: 1,
                                items: { type: "number" },
                                uniqueItems: true,
                            },
                            safiSchema
                        }
                    }
                },
            ]
        }
    }
}

const comercialSchema = {
    title: "Información Cliente Empresa",
    type: "object",
    properties: {
        "nombreEmpresa": {
            title: "Nombre Empresa",
            type: "string",
        },
        "actividadPrincipal": {
            title: "Actividad Principal",
            type: "string",
            enum: dropdownOptions.actividades,
        },
        "tipoIdentificacionComercial": {
            title: "Tipo de Identificación",
            type: "number",
            oneOf: [
                { title: "RNC", const: 4 },
            ],
            default: 4
        },
        "numIdentificacionRNC": {
            title: "No. de identificación RNC",
            type: "string",
            minLength: 9,
            maxLength: 9,
        },
        "nombreContacto": {
            title: "Nombre Contacto",
            type: "string",
        },
        "apellidoContacto": {
            title: "Apellido Contacto",
            type: "string",
        },
        "posicionContacto": {
            title: "Posición Contacto",
            type: "string",
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        "comentarios": leadChannel
    },
    required: ["nombreEmpresa", "actividadPrincipal", "tipoIdentificacionComercial",
        "nombreContacto", "apellidoContacto",
        "posicionContacto", "celular", "email", "numIdentificacionRNC"
    ],
    dependencies: {
    },
}


form.schema = {
    title: " ",
    type: "object",
    properties: {
        clientTypeId,
        preferredBranchId,
        "tipoVinculacion": {
            title: "¿Tipo de Vinculación?",
            type: "string",
            oneOf: [
                {
                    title: "Cliente Nuevo JMMB",
                    const: "Cliente Nuevo JMMB"
                },
                {
                    title: "Vinculación a filial adicional",
                    const: "Vinculacion a filial adicional"
                },
                {
                    title: "Cliente existente solo Solicitud de Préstamo",
                    const: "Cliente existente solo Solicitud de Prestamo"
                },
            ],
        },
        "duracionRelacion": {
            title: "Duración de la relación",
            type: "string",
            enumNames: [
                "Cliente Nuevo ( Menos de 1 año)",
                "Más de 1 año menos de 3 años",
                "Más de 3 años menos de 5 años",
                "Más de 5 años"
            ],
            enum: [
                "Cliente Nuevo ( Menos de 1 ano)",
                "Mas de 1 ano menos de 3 anos",
                "Mas de 3 anos menos de 5 anos",
                "Mas de 5 anos"
            ],
        },
        comercialSchema,
        "infoRepLegal": infoRepLegal,
        "datosEmpresa": datosEmpresaSchema,
        "personaPoliticamenteExpuesta": personaPoliticamenteExpuestaSchema,
        "declaracionJurada": declaracionJuradaSchema,
        "kycCommercialClientSchema": kycCommercialClientSchema,
        "otraInfo": otraInfoSchema,
        "perfilInvers": perfilInversionistaSchema,
        "situacionFinanciera": situacionFinancieraSchema,
        "analisisPatrimonio": analisisPatrimonioSchema,
        "resultadoPerfil": resultadoPerfilSchema,
        "firmantesAdicionales": firmantesAdicionalesSchema,
        "matrizRiesgo": matrizRiesgoSchema,
        "soporteDebidaDiligencia": soporteDebidaDiligenciaSchema,
        "additionalFiles": {
            title: "Archivos adicionales",
            type: "array",
            items: {
                "properties": {
                    "documentName": {
                        title: "Nombre archivo",
                        type: "string"
                    },
                    "document": {
                        title: "Archivo",
                        type: "number"
                    },
                }
            },
        },
    },
}

form.uiSchema = {
    "preferredBranchId": { "ui:field": "LookupFormField", "ui:readonly": true },
    "clientTypeId": {
        "ui:widget": "radio",
        "ui:readonly": true,
        "ui:options": {
            inline: true
        },
    },
    "comercialSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nombreEmpresa",
            "actividadPrincipal",
            "tipoIdentificacionComercial",
            "numIdentificacionRNC",
            "numIdentificacionCedula",
            "*"
        ],
        "tipoIdentificacionComercial": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "nombreContacto": {
            "classNames": "from-col-1",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        }
    },

    "perfilClienteSchema": {
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "inversionistaInstitucional": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "accionistaPersonaJuridica20PorcientoAcciones": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "accionistasCiudadanoResidenteNorteamericano": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "accionistaPersonaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "kycCommercialClientSchema": {
        "classNames": "smallThreeRows",
        "ui:order": [
            "institucionVincularse",
            "productosBanco",
            "productosPuesto",
            "productosSAFI",
            "*",
        ],
        "savingsAccountSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "depositoEstimadoRD": { "classNames": "from-col-1 title-2-lines" },
            "origenFondosRD": { "classNames": "title-2-lines" },
            "depositoEstimadoUSD": { "classNames": "from-col-1 title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosRDOtro": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" }
        },
        "institucionVincularse": {
            "classNames": "from-col-1 to-col-4",
            "ui:widget": "checkboxes",
            "ui:options": { inline: true },
        },
        "productosBanco": {
            "classNames": "from-col-1",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": {
                inline: false
            },
        },
        "cuentaAhorroBasica": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "soloMantieneEstaCuenta": {
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "productosPuesto": {
            "classNames": "from-col-2",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "productosSAFI": {
            "classNames": "from-col-3",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "inversionesSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio",
            ],
            "inversionEstimadaUSD": { "classNames": "title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" },
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "safiSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "*",
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio"
            ],
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "solicitudPrestamoSchema": {
            "classNames": "from-col-1 to-col-4 smallTwoRows default-all-row",
            "montoDeseadoPrestamo": {
                "classNames": "from-col-1 to-col-2"
            },
            "tasaCambio": {
                "classNames": "from-col-2 to-col-3",
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            },
            "proposito": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 3
                },
            },
            "facilidadesSolicitadas": {
                "ui:order": [
                    "*",
                    "incluyeGarantiaSolidaria",
                    "tipoGarantia",
                    "comentarios"
                ],
                
                "comentarios": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantiaSolidaria": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
            },
            "incluyeGarantia": {
                "garantiaSelection": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "garantiaSolidariaSchema": {
                    "garantiaSolidaria": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "descGarantias": {
                        "descGarantia":{
                            "ui:widget": "textarea",
                            "ui:options": {
                                rows: 3
                            },
                        },
                        "tipoDeGarantia":{
                            "ui:widget": "checkboxes",
                        },
                        "contratoElaboradoPor":{
                            "ui:widget": "radio"
                        },
                        "ui:order": [
                            "tipoDeGarantia",
                            "contratoElaboradoPor",
                            "*"
                        ],
                    },
                },
                "comentarios": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 4
                    },
                },
                "ui:order": [
                    "*", "garantiaSelection", "garantiaSolidariaSchema", "comentarios",
                ],
            },
        },
        "informeVisita":{
            "classNames": "from-col-1 to-col-4",
            "participantesReunion":{
                "ui:options": {
                    "orderable": false
                },
            },
            "principalesClientes":{
                "ui:options": {
                    "orderable": false
                },
            },
            "principalesProveedores":{
                "ui:options": {
                    "orderable": false
                },
            },
            "principalesCompetidores":{
                "ui:options": {
                "orderable": false
                },
            }
        },
        "financialCertSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
    },
    "infoRepLegal": {
        "classNames": "smallTwoRows",
        "celularRep": {
            "ui:widget": "PhoneWidget",
        },
        "telefonoRep": {
            "ui:widget": "PhoneWidget",
        }
    },
    "estadoCivil": {
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "datosEmpresa": {
        "classNames": "smallTwoRows",
        "prodServOfrecen": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "empresasAfiliadas": {
            "classNames": "from-col-1 to-col-3",
            "ui:options": {
                "orderable": false
            },

        },
        "principalesClientes": {
            "classNames": "from-col-1 to-col-3",
            "ui:options": {
                "orderable": false
            },

        },
        "principalesAccionistas": {
            "classNames": "from-col-1 to-col-3",
            "princAccionList": {
                "ui:options": {
                    "orderable": false
                },
            }
        },
        "miembrosOrganoDireccion": {
            "classNames": "from-col-1 to-col-3",
            "ui:options": {
                "orderable": false
            },
        },
        "nombreDirectores": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 2
            },
        }
    },
    "personaPoliticamenteExpuesta": {
        "ui:options": {
            "orderable": false,
        }
    },
    "otraInfo": {
        "beneficiariosDeTransaccionDiferentes": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "vinculadoJMMB": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "cantOperaciones": {
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "ui:order": [
            "cantOperaciones",
            "otroOperaciones",
            "formaTransacciones",
            "beneficiariosDeTransaccionDiferentes",
            "nombreBeneficiario",
            "*"
        ]
    },
    "perfilInvers": {
        "classNames": "from-col-1 to-col-4 smallThreeRows",
        "ui:order": [
            "riskProdServTitle",
            "riesgoProductosServiciosEdad",
            "riesgoProductosServiciosObjetivo",
            "criteriosIdeasRentabilidad",
            "*",
        ],
        "riesgoProductosServiciosEdad": {
            "classNames": "from-col-1 radio-column",
            "ui:widget": "radio",
        },
        "riesgoProductosServiciosObjetivo": {
            "classNames": "from-col-2 pad-right radio-column",
            "ui:widget": "radio",
        },
        "criteriosIdeasRentabilidad": {
            "classNames": "radio-column",
            "ui:widget": "radio",
        },
        "rentabilidadMinima": {
            "ui:widget": "radio",
        },
        "plazoDeseaCumplirObjetivos": {
            "ui:widget": "radio",
        },
        "plazoAnticipaNecesitarInversiones": {
            "ui:widget": "radio",
        },
        "capacidadAbsorverPerdidas": {
            "ui:widget": "radio",
        },
        "operacionesFinancierasNegociado": {
            "classNames": "from-col-1 to-col-4",
            "titles": {
                "classNames": "smallFiveRows",
                "col1": { "classNames": "from-col-3" },
            },
            "inversionCertificadosFinancieros": {
                "classNames": "smallFiveRows",
                "title": { "classNames": "from-col-1 to-col-3" },
            },
            "bonosSoberanos": {
                "classNames": "smallFiveRows",
                "title": { "classNames": "from-col-1 to-col-3" },
            },
            "bonosCorporativos": {
                "classNames": "smallFiveRows",
                "title": { "classNames": "from-col-1 to-col-3" },
            },
            "fondosInversionesMutuos": {
                "classNames": "smallFiveRows",
                "title": { "classNames": "from-col-1 to-col-3" },
            },
            "fondosCerrados": {
                "classNames": "smallFiveRows",
                "title": { "classNames": "from-col-1 to-col-3" },
            },
            "otrosDerivados": {
                "classNames": "smallFiveRows",
                "title": { "classNames": "from-col-1" },
            },
        },
    },

    "situacionFinanciera": {
        "classNames": "from-col-1 to-col-4 smallThreeRows",
        "ui:order": [
            "ingresosAnuales",
            "totalActivosLiquidos",
            "patrimonioTotal",

            "capacidadAhorro",
            "porcientoCuentasAhorroOCorriente",
            "porcientoDeudas",
            "porcientoOtro",

            "referenciasBancarias",
            "referenciasComerciales",
        ],
        "ingresosAnuales": {
            "ui:widget": "radio",
        },
        "totalActivosLiquidos": {
            "ui:widget": "radio",
        },
        "patrimonioTotal": {
            "ui:widget": "radio",
        },
        "porcientoOtro": {
            "classNames": "from-col-2",
        },
        "referenciasBancarias": {
            "classNames": "from-col-1 to-col-4",
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
            },
        },
        "referenciasComerciales": {
            "classNames": "from-col-1 to-col-4",
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
            },
        },
    },

    "analisisPatrimonio": {
        "classNames": "from-col-1 to-col-4",
        "PeriodosFiscales1": {
            "classNames": "smallFourRows",
        },
        "PeriodosFiscales2": {
            "classNames": "smallFourRows",
        },
    },
    "resultadoPerfil": {
        "classNames": "from-col-1 to-col-4",
        "ui:order": [
            "fechaActualizacionExpediente",
            "indicarInformacionActualizadaYFuente",
            "relacionCaraCara",
            "canalNoCaraCara",
            "otroCanalNoCaraCara",
            "*",
            "clienteProfesional",
            "consideradoNoProfesional",
            "tolerancia",
            "clienteBancarizado",
            "acuerdoConPerfilObtenido"

        ],
        "fechaActualizacionExpediente": { yearsRange: [1900, 2030], },
        "relacionCaraCara": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "resumenCliente": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        },
        "productoSolicitadoAjustaCliente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "capacidadPatrimonial": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "clienteVinculado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "acuerdoConPerfilObtenido": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "clienteProfesional": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "clienteBancarizado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "firmantesAdicionales": {
        "classNames": "smallFourRows",
        "nombreCorredor": {
            "classNames": "from-col-1 to-col-3",
        },
        "numeroCorredor": {
            "classNames": "from-col-3 to-col-5",
        },
        "firmantes": {
            "classNames": "from-col-1 to-col-5",
            "ui:options": {
                "orderable": false,
            }
        }
    },
    "informeVisita": {
        "motivoDeVisita": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "participantesReunion": {
            "ui:options": {
                "orderable": false,
            }
        },
        "breveDescEntorno": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "breveAnalisis": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "brevDescInst": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "principalesClientes": {
            "ui:options": {
                "orderable": false,
            }
        },
        "principalesProveedores": {
            "ui:options": {
                "orderable": false,
            }
        },
        "principalesCompetidores": {
            "ui:options": {
                "orderable": false,
            }
        },
        "listProv": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "perspectivaCortoPlazo": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "otrasObs": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "compRel": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "ui:order": [
            "*", "motivoDeVisita", "otroMotivo", "participantesReunion", "breveDescEntorno", "breveAnalisis", "brevDescInst", "principalesClientes", "principalesProveedores", "principalesCompetidores", "perspectivaCortoPlazo", "otrasObs"
        ],
    },
    "matrizRiesgo": { "ui:field": "RiskMatrixFieldBusiness" },
    "soporteDebidaDiligencia": {
        "ui:field": "HideObjectField",
        "classNames": "soporteDebidaDiligencia",
        "informeVisita": { "ui:field": "FileField" },
        "revisionListaOFAC": { "ui:field": "FileField" },
        "revisionBuroCredito": { "ui:field": "FileField" },
        "revisionListaONU": { "ui:field": "FileField" },
        "matrizRiesgo": { "ui:field": "FileField" },
        "tieneOFAC": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tieneONU": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "additionalFiles": {
        "classNames": "additionalFiles",
        "items": { "document": { "ui:field": "FileField" } },
        "ui:options": {
            "orderable": false,
        }
    },
    "ui:order": [
        "*",
    ]
}

form.objectMap = {
    "clients[0].clientTypeId": "clientTypeId",
    "clients[0].preferredBranchId": "preferredBranchId",
    "clients[0].relationshipType": "tipoVinculacion",
    "clients[0].relationshipDuration": "duracionRelacion",

    "productInstitutions": "kycCommercialClientSchema.institucionVincularse",
    "productIds.1": "kycCommercialClientSchema.productosBanco",
    "productIds.2": "kycCommercialClientSchema.productosPuesto",
    "productIds.3": "kycCommercialClientSchema.productosSAFI",

    // Forma de las transacciones
    "clients[0].businessClientBasicInfo.transFormCash": "formaTransacciones.transacciones.efectivo",
    "clients[0].businessClientBasicInfo.transFormCheck": "formaTransacciones.transacciones.cheque",
    "clients[0].businessClientBasicInfo.transFormTransfer": "formaTransacciones.transacciones.transferencias",
    "clients[0].businessClientBasicInfo.transFormIntl": "formaTransacciones.transacciones.internacionales",
    "clients[0].businessClientBasicInfo.transFormNatl": "formaTransacciones.transacciones.nacionales",


    // DECLARACION JURADA
    "clients[0].businessClientBasicInfo.swornDeclarationArray[0]": "declaracionJurada.declaracionJurada1",
    "clients[0].businessClientBasicInfo.swornDeclarationArray[1]": "declaracionJurada.declaracionJurada2",
    "clients[0].businessClientBasicInfo.swornDeclarationArray[2]": "declaracionJurada.declaracionJurada3",
    "clients[0].businessClientBasicInfo.swornDeclarationArray[3]": "declaracionJurada.declaracionJurada4",

    // OPERACIONES FINANCIERAS NEGOCIADO
    "clients[0].businessClientInvestorProfile.investmentInFinanceCertificatesYearManagingProduct": "perfilInvers.operacionesFinancierasNegociado.inversionCertificadosFinancieros.anosManejandoProducto",
    "clients[0].businessClientInvestorProfile.investmentInFinanceCertificatesYearlyOperations": "perfilInvers.operacionesFinancierasNegociado.inversionCertificadosFinancieros.numOperacionesAnuales",
    "clients[0].businessClientInvestorProfile.investmentInFinanceCertificatesKnowledgeLevel": "perfilInvers.operacionesFinancierasNegociado.inversionCertificadosFinancieros.nivelConocimiento",

    "clients[0].businessClientInvestorProfile.riskFreeBondsYearManagingProduct": "perfilInvers.operacionesFinancierasNegociado.bonosSoberanos.anosManejandoProducto",
    "clients[0].businessClientInvestorProfile.riskFreeBondsYearlyOperations": "perfilInvers.operacionesFinancierasNegociado.bonosSoberanos.numOperacionesAnuales",
    "clients[0].businessClientInvestorProfile.riskFreeBondsKnowledgeLevel": "perfilInvers.operacionesFinancierasNegociado.bonosSoberanos.nivelConocimiento",

    "clients[0].businessClientInvestorProfile.corporateBondsYearManagingProduct": "perfilInvers.operacionesFinancierasNegociado.bonosCorporativos.anosManejandoProducto",
    "clients[0].businessClientInvestorProfile.corporateBondsYearlyOperations": "perfilInvers.operacionesFinancierasNegociado.bonosCorporativos.numOperacionesAnuales",
    "clients[0].businessClientInvestorProfile.corporateBondsKnowledgeLevel": "perfilInvers.operacionesFinancierasNegociado.bonosCorporativos.nivelConocimiento",

    "clients[0].businessClientInvestorProfile.mutualFundsYearManagingProduct": "perfilInvers.operacionesFinancierasNegociado.fondosInversionesMutuos.anosManejandoProducto",
    "clients[0].businessClientInvestorProfile.mutualFundsYearlyOperations": "perfilInvers.operacionesFinancierasNegociado.fondosInversionesMutuos.numOperacionesAnuales",
    "clients[0].businessClientInvestorProfile.mutualFundsKnowledgeLevel": "perfilInvers.operacionesFinancierasNegociado.fondosInversionesMutuos.nivelConocimiento",

    "clients[0].businessClientInvestorProfile.closedFundsYearManagingProduct": "perfilInvers.operacionesFinancierasNegociado.fondosCerrados.anosManejandoProducto",
    "clients[0].businessClientInvestorProfile.closedFundsYearlyOperations": "perfilInvers.operacionesFinancierasNegociado.fondosCerrados.numOperacionesAnuales",
    "clients[0].businessClientInvestorProfile.closedFundsKnowledgeLevel": "perfilInvers.operacionesFinancierasNegociado.fondosCerrados.nivelConocimiento",

    "clients[0].businessClientInvestorProfile.otherDerivatives": "perfilInvers.operacionesFinancierasNegociado.otrosDerivados.indicar",
    "clients[0].businessClientInvestorProfile.otherDerivativesYearManagingProduct": "perfilInvers.operacionesFinancierasNegociado.otrosDerivados.anosManejandoProducto",
    "clients[0].businessClientInvestorProfile.otherDerivativesYearlyOperations": "perfilInvers.operacionesFinancierasNegociado.otrosDerivados.numOperacionesAnuales",
    "clients[0].businessClientInvestorProfile.otherDerivativesKnowledgeLevel": "perfilInvers.operacionesFinancierasNegociado.otrosDerivados.nivelConocimiento",

    // DATOS GENERALES EMPRESA
    "clients[0].businessClientBasicInfo.businessName": "comercialSchema.nombreEmpresa",
    "clients[0].businessClientBasicInfo.businessMainActivity": "comercialSchema.actividadPrincipal",
    "clients[0].businessClientBasicInfo.identificationType": "comercialSchema.tipoIdentificacionComercial",
    "clients[0].businessClientBasicInfo.contactFirstName": "comercialSchema.nombreContacto",
    "clients[0].businessClientBasicInfo.contactLastName": "comercialSchema.apellidoContacto",
    "clients[0].businessClientBasicInfo.contactPosition": "comercialSchema.posicionContacto",
    "clients[0].businessClientBasicInfo.phone": "comercialSchema.telefono",
    "clients[0].businessClientBasicInfo.phoneMobile": "comercialSchema.celular",
    "clients[0].businessClientBasicInfo.contactEmail": "comercialSchema.email",
    "clients[0].businessClientBasicInfo.comments": "comercialSchema.comentarios",
    "clients[0].businessClientBasicInfo.identificationNumber": "comercialSchema.numIdentificacionRNC",

    // LEGAL REPRESENTATIVE
    "clients[0].businessClientBasicInfo.legalRepresentativeFirstName": "infoRepLegal.nombreRep",
    "clients[0].businessClientBasicInfo.legalRepresentativeLastName": "infoRepLegal.apellidoRep",
    "clients[0].businessClientBasicInfo.legalRepresentativePosition": "infoRepLegal.posRep",
    "clients[0].businessClientBasicInfo.legalRepresentativeIdType": "infoRepLegal.tipoId",
    "clients[0].businessClientBasicInfo.legalRepresentativeIdNumber": "infoRepLegal.numeroId",
    "clients[0].businessClientBasicInfo.legalRepresentativeEmail": "infoRepLegal.emailRep",
    "clients[0].businessClientBasicInfo.legalRepresentativePhone": "infoRepLegal.telefonoRep",
    "clients[0].businessClientBasicInfo.legalRepresentativeCellphone": "infoRepLegal.celularRep",

    // DATOS EMPRESA
    "clients[0].businessClientBasicInfo.dateFounded": "datosEmpresa.fechaConst",
    "clients[0].businessClientBasicInfo.placeFounded": "datosEmpresa.lugarConst",
    "clients[0].businessClientBasicInfo.address": "datosEmpresa.dirResidencia",
    "clients[0].businessClientBasicInfo.addressSector": "datosEmpresa.sector",
    "clients[0].businessClientBasicInfo.city": "datosEmpresa.ciudad",
    "clients[0].businessClientBasicInfo.country": "datosEmpresa.pais",
    "clients[0].businessClientBasicInfo.nationalGeographicZone": "datosEmpresa.zonaGeograficaNacional",
    "clients[0].businessClientBasicInfo.businessWebsite": "datosEmpresa.website",
    "clients[0].businessClientGeneralInfo.directorNames": "datosEmpresa.nombreDirectores",

    // >>>>>>>> BCGI <<<<<<<<<<////
    "clients[0].businessClientGeneralInfo.numEmployees": "datosEmpresa.numEmpleados",
    "clients[0].businessClientGeneralInfo.avgMonthlySales": "datosEmpresa.promMensVentas",
    "clients[0].businessClientGeneralInfo.offering": "datosEmpresa.prodServOfrecen",

    "clients[0].businessClientGeneralInfo.associatedBusinesses": {
        array: "datosEmpresa.empresasAfiliadas",
        map: {
            "nameOrSocialReason": "nombreRazonSocial",
            "rncNumber": "rncNumber",
        }
    },

    "clients[0].businessClientGeneralInfo.mainClients": {
        array: "datosEmpresa.principalesClientes",
        map: {
            "nameMainClient": "principalesClientesField",
        }
    },
    "clients[0].businessClientGeneralInfo.mainProviders": {
        array: "datosEmpresa.principalesProveedores",
        map: {
            "name": "nombre",
        }
    },

    "clients[0].businessClientGeneralInfo.mainInvestors": {
        array: "datosEmpresa.principalesAccionistas.princAccionList",
        map: {
            "name": "nombreCompleto",
            "shareholding": "participacionAccionaria",
            "nationality": "nacionalidad",
            "hasUnitedStatesCitizenship": "ciudadaniaUSA",
            "identificationNumber": "numeroIdent",
            "finalBeneficiary": "benefFinal",
        }
    },

    "clients[0].businessClientGeneralInfo.businessClientBoardMembers": {
        array: "datosEmpresa.miembrosOrganoDireccion",
        map: {
            "boardMemberName": "nombreCompleto",
            "boardMemberPosition": "posicion",
            "boardMemberNationality": "nacionalidad",
            "boardMemberIdNumber": "numeroIdent",
        }
    },


    // POLITICAL EXPOSURE
    "clients[0].businessClientPoliticalExposureInfo": {
        array: "personaPoliticamenteExpuesta",
        map: {
            "politicalExposureName": "nombreCompleto",
            "politicalExposurePosition": "cargo",
            "politicalExposureDesignationDate": "fechaDesignacion",
            "politicalExposureRemovalDate": "fechaRemocion",
            "politicalExposureCountry": "pais",
        }
    },

    "clients[0].businessClientGeneralInfo.qtyExpectedOperations": "otraInfo.cantOperaciones",
    "clients[0].businessClientGeneralInfo.qtyExpectedOperationsOther": "otraInfo.otroOperaciones",
    "clients[0].businessClientGeneralInfo.transactionForm": "otraInfo.formaTransacciones",
    "clients[0].businessClientGeneralInfo.areBeneficiariesOtherThanSelf": "otraInfo.beneficiariosDeTransaccionDiferentes",
    "clients[0].businessClientGeneralInfo.beneficiarieIds": "otraInfo.identBeneficiarioFinal",
    "clients[0].businessClientGeneralInfo.beneficiaries": "otraInfo.nombreBeneficiario",
    "clients[0].businessClientGeneralInfo.relatedPersonelDeclarations": "otraInfo.declaracionPersRel",
    "clients[0].businessClientGeneralInfo.otherRelevantInformation": "otraInfo.otraInfo",
    "clients[0].businessClientGeneralInfo.relatedCompany": "otraInfo.empresaVinculada",
    "clients[0].businessClientGeneralInfo.isRelatedToJMMB": "otraInfo.vinculadoJMMB",


    "clients[0].businessClientInvestorProfile.clientAge": "perfilInvers.riesgoProductosServiciosEdad",
    "clients[0].businessClientInvestorProfile.mainGoal": "perfilInvers.riesgoProductosServiciosObjetivo",
    "clients[0].businessClientInvestorProfile.riskProfile": "perfilInvers.criteriosIdeasRentabilidad",
    "clients[0].businessClientInvestorProfile.minimumRentability": "perfilInvers.rentabilidadMinima",
    "clients[0].businessClientInvestorProfile.academicLevel": "perfilInvers.nivelAcademico",
    "clients[0].businessClientInvestorProfile.expectedGoalTerm": "perfilInvers.plazoDeseaCumplirObjetivos",
    "clients[0].businessClientInvestorProfile.needOfInvestmentTerm": "perfilInvers.plazoAnticipaNecesitarInversiones",
    "clients[0].businessClientInvestorProfile.abilityToAbsorbLoses": "perfilInvers.capacidadAbsorverPerdidas",

    // SITUACION FINANCIERA
    "clients[0].businessClientFinancialCapacity.yearlyIncome": "situacionFinanciera.ingresosAnuales",
    "clients[0].businessClientFinancialCapacity.savingCapacityPct": "situacionFinanciera.capacidadAhorro",
    "clients[0].businessClientFinancialCapacity.liquidAssets": "situacionFinanciera.totalActivosLiquidos",
    "clients[0].businessClientFinancialCapacity.liquidAssetsInCheckingsPct": "situacionFinanciera.porcientoCuentasAhorroOCorriente",
    "clients[0].businessClientFinancialCapacity.netWorth": "situacionFinanciera.patrimonioTotal",
    "clients[0].businessClientFinancialCapacity.debtPct": "situacionFinanciera.porcientoDeudas",
    "clients[0].businessClientFinancialCapacity.otherPct": "situacionFinanciera.porcientoOtro",

    "clients[0].businessClientFinancialCapacity.bankingReferences": {
        array: "situacionFinanciera.referenciasBancarias",
        map: {
            "bank": "banco",
            "typeOfAccount": "tipoCuenta",
            "phone": "telefono",
            "oficial": "oficial",
        }
    },

    "clients[0].businessClientFinancialCapacity.commercialReferences": {
        array: "situacionFinanciera.referenciasComerciales",
        map: {
            "name": "nombre",
            "phone": "telefono",
            "address": "direccion",
        }
    },

    // ANALISIS PATRIMONIO
    "clients[0].businessClientNetWorthInfo.fiscalYearOne": "analisisPatrimonio.PeriodosFiscales1.anosPeriodosFiscales1",
    "clients[0].businessClientNetWorthInfo.yearOneRDSNetWorth": "analisisPatrimonio.PeriodosFiscales1.totalPatrimonio1",
    "clients[0].businessClientNetWorthInfo.yearOneRDSLiquidAssets": "analisisPatrimonio.PeriodosFiscales1.totalActivosLiquidos1",
    "clients[0].businessClientNetWorthInfo.yearOneEquityAnalisis": "analisisPatrimonio.PeriodosFiscales1.resultadoAnalisisPatrimonio1",

    "clients[0].businessClientNetWorthInfo.fiscalYearTwo": "analisisPatrimonio.PeriodosFiscales2.anosPeriodosFiscales2",
    "clients[0].businessClientNetWorthInfo.yearTwoRDSNetWorth": "analisisPatrimonio.PeriodosFiscales2.totalPatrimonio2",
    "clients[0].businessClientNetWorthInfo.yearTwoRDSLiquidAssets": "analisisPatrimonio.PeriodosFiscales2.totalActivosLiquidos2",
    "clients[0].businessClientNetWorthInfo.yearTwoEquityAnalisis": "analisisPatrimonio.PeriodosFiscales2.resultadoAnalisisPatrimonio2",

    // RESULTADO PERFIL
    "clients[0].businessClientProfileResult.lastUpdate": "resultadoPerfil.fechaActualizacionExpediente",
    "clients[0].businessClientProfileResult.updatedInfo": "resultadoPerfil.indicarInformacionActualizadaYFuente",
    "clients[0].businessClientProfileResult.isFaceToFace": "resultadoPerfil.relacionCaraCara",
    "clients[0].businessClientProfileResult.relationshipChannel": "resultadoPerfil.canalNoCaraCara",
    "clients[0].businessClientProfileResult.summary": "resultadoPerfil.resumenCliente",
    "clients[0].businessClientProfileResult.isProductAdjusted": "resultadoPerfil.productoSolicitadoAjustaCliente",
    "clients[0].businessClientProfileResult.typeOfProspect": "resultadoPerfil.clienteProfesional",
    "clients[0].businessClientProfileResult.considerNonProfessional": "resultadoPerfil.consideradoNoProfesional",
    "clients[0].businessClientProfileResult.relationshipChannelOther": "resultadoPerfil.otroCanalNoCaraCara",
    "clients[0].businessClientProfileResult.tolerance": "resultadoPerfil.tolerancia",
    "clients[0].businessClientProfileResult.acknowledgement": "resultadoPerfil.acuerdoConPerfilObtenido",
    "clients[0].businessClientProfileResult.hasNetWorthGT50Mil": "resultadoPerfil.capacidadPatrimonial",
    "clients[0].businessClientProfileResult.isAssociatedToJMMB": "resultadoPerfil.clienteVinculado",



    //                   FIRMANTES
    "clients[0].businessClientProfileResult.brokerName": "firmantesAdicionales.nombreCorredor",
    "clients[0].businessClientProfileResult.brokerNumber": "firmantesAdicionales.numeroCorredor",
    "clients[0].businessClientProfileResult.businessClientSigners": {
        array: "firmantesAdicionales.firmantes",
        map: {
            "name": "firmante",
        }
    },

    // CUENTA DE AHORROS
    "clients[0].businessClientBasicInfo.hasBasicSaving": "kycCommercialClientSchema.cuentaAhorroBasica",
    "clients[0].businessClientBasicInfo.onlyMaintainsThisAccount": "kycCommercialClientSchema.soloMantieneEstaCuenta",
    "clients[0].businessClientBasicInfo.savingsException": "kycCommercialClientSchema.excepcionCuentaAhorro",


    // SOLICITUD PRESTAMO
    "caseLoanInfo.loanHasGuarantor": "kycCommercialClientSchema.solicitudPrestamoSchema.prestamoGarante",
    "caseLoanInfo.loanAmount": "kycCommercialClientSchema.solicitudPrestamoSchema.montoDeseadoPrestamo",
    "caseLoanInfo.exchangeRate": "kycCommercialClientSchema.solicitudPrestamoSchema.tasaCambio",
    "caseLoanInfo.loanDestination": "kycCommercialClientSchema.solicitudPrestamoSchema.destinoFondos",
    "caseLoanInfo.businessPropertyOwnershipType": "kycCommercialClientSchema.solicitudPrestamoSchema.propLocalEmpresa",

    "clients[0].businessClientLoanInfo.purpose": "kycCommercialClientSchema.solicitudPrestamoSchema.proposito",

    "clients[0].businessClientLoanInfo.businessLoanFacilities": {
        array: "kycCommercialClientSchema.solicitudPrestamoSchema.descFacilidades",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCancelled": "seraCancelado"
        }
    },

    "caseLoanInfo.includesCollateral": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSelection",
    "caseLoanInfo.comments": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.comentarios",
    "caseLoanInfo.guarranteeType": "kycCommercialClientSchema.solicitudPrestamoSchema.tipoGarantia",

    // "clients[0].businessClientLoanInfo.termInMonths": "kycCommercialClientSchema.solicitudPrestamo.plazo",

    //  GARANTIAS <<<<<
    "clients[0].businessClientLoanInfo.hasWarranty": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSelection",
    "clients[0].businessClientLoanInfo.comments": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.comentarios",
    "clients[0].businessClientLoanInfo.hasSolidarityGuarantee": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.garantiaSolidaria",

    "clients[0].businessClientLoanInfo.warrantyNumber": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.descGarantias.noDeGarantia",
    "clients[0].businessClientLoanInfo.numAssociatedFacilities": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.descGarantias.noFacilidadAsociada",
    "clients[0].businessClientLoanInfo.warrantyDescription": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.descGarantias.descGarantia",
    "clients[0].businessClientLoanInfo.value": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.descGarantias.valGarantia",

    "clients[0].businessClientLoanInfo.selectedWarranties": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.descGarantias.tipoDeGarantia",
    "clients[0].businessClientLoanInfo.contractDrafter": "kycCommercialClientSchema.solicitudPrestamoSchema.incluyeGarantia.garantiaSolidariaSchema.descGarantias.contratoElaboradoPor",
 
    //  INFORME VISITA
    "clients[0].businessClientVisitReport.visitReportFileId": "soporteDebidaDiligencia.informeVisita",
    "clients[0].businessClientVisitReport.visitDateTime": "informeVisita.fechaHora",
    "clients[0].businessClientVisitReport.visitReason": "informeVisita.motivoDeVisita",
    "clients[0].businessClientVisitReport.businessHistory": "informeVisita.breveDescEntorno",
    "clients[0].businessClientVisitReport.businessProfileAnalysis": "informeVisita.breveAnalisis",
    "clients[0].businessClientVisitReport.physicalFacilities": "informeVisita.brevDescInst",
    "clients[0].businessClientVisitReport.shortTermSalesPerspective": "informeVisita.perspectivaCortoPlazo",
    "clients[0].businessClientVisitReport.otherExecutiveObservations": "informeVisita.otrasObs",
    "clients[0].businessClientVisitReport.visitReasonOther": "informeVisita.otroMotivo",

    "clients[0].businessClientVisitReport.businessClientMeetingParticipants": {
        array: "informeVisita.participantesReunion",
        map: {
            "name": "nombre",
            "position": "cargo",
        }
    },

    "clients[0].businessClientVisitReport.businessClientPrincipalClients": {
        array: "informeVisita.principalesClientes",
        map: {
            "name": "principalCliente",
            "salesTerm": "terminosVenta",
        }
    },

    "clients[0].businessClientVisitReport.businessClientPrincipalProviders": {
        array: "informeVisita.principalesProveedores",
        map: {
            "name": "principalProveedor",
            "buyTerm": "terminosCompra",
            "phone": "telefono",
        }
    },

    "clients[0].businessClientVisitReport.businessClientPrincipalCompetitors": {
        array: "informeVisita.principalesCompetidores",
        map: {
            "name": "principalCompetidor",
        }
    },


    //   SOPORTE DEBIDA DILIGENCIA
    "clients[0].businessClientDueDiligenceReport.inOFACList": "soporteDebidaDiligencia.tieneOFAC",
    "clients[0].businessClientDueDiligenceReport.inONUList": "soporteDebidaDiligencia.tieneONU",
    "clients[0].businessClientDueDiligenceReport.riskLevel": "soporteDebidaDiligencia.nivelRiesgo",

    "clients[0].businessClientDueDiligenceReport.ofacRevisionFileId": "soporteDebidaDiligencia.revisionListaOFAC",
    "clients[0].businessClientDueDiligenceReport.creditBuroRevisionFileId": "soporteDebidaDiligencia.revisionBuroCredito",
    "clients[0].businessClientDueDiligenceReport.onuRevisionFileId": "soporteDebidaDiligencia.revisionListaONU",
    "clients[0].businessClientDueDiligenceReport.riskMatrixFileId": "soporteDebidaDiligencia.matrizRiesgo",

    "clients[0].businessClientDueDiligenceReport.dueDiligenceResult": "soporteDebidaDiligencia.sentBack",

    "clients[0].businessClientDueDiligenceReport.additionalFilesList.items": {
        array: "additionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },

    // Puesto

    "caseInvestmentInfo.usdAmountPuesto": "kycCommercialClientSchema.inversionesSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountPuesto": "kycCommercialClientSchema.inversionesSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRatePuesto": "kycCommercialClientSchema.inversionesSchema.tasaCambio",

    // Certificado Financiero

    "caseInvestmentInfo.rdsAmountCertificate": "kycCommercialClientSchema.financialCertSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginCertificate": "kycCommercialClientSchema.financialCertSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherCertificate": "kycCommercialClientSchema.financialCertSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateCertificate": "kycCommercialClientSchema.financialCertSchema.tasaCambio",

    // SAFI

    "caseInvestmentInfo.usdAmountSAFI": "kycCommercialClientSchema.safiSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginSAFI": "kycCommercialClientSchema.safiSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherSAFI": "kycCommercialClientSchema.safiSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountSAFI": "kycCommercialClientSchema.safiSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginSAFI": "kycCommercialClientSchema.safiSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherSAFI": "kycCommercialClientSchema.safiSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateSAFI": "kycCommercialClientSchema.safiSchema.tasaCambio",

    // Cuenta Ahorro

    "clients[0].businessClientBasicInfo.rdsAmountSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.depositoEstimadoRD",
    "clients[0].businessClientBasicInfo.rdsFundsOriginSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosRD",
    "clients[0].businessClientBasicInfo.rdsFundsOriginOtherSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosRDOtro",
    "clients[0].businessClientBasicInfo.rdsSelection": "kycCommercialClientSchema.savingsAccountSchema.rdCurrency",
    "clients[0].businessClientBasicInfo.usdSelection": "kycCommercialClientSchema.savingsAccountSchema.usCurrency",
    "clients[0].businessClientBasicInfo.usdAmountSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.depositoEstimadoUSD",
    "clients[0].businessClientBasicInfo.usdFundsOriginSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosUSD",
    "clients[0].businessClientBasicInfo.usdFundsOriginOtherSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosUSDOtro",

    "clients[0].businessClientBasicInfo.isSubjectTo15517": "datosEmpresa.sujetoObligadoLey155",

}


export default form;