import dropdownOptions from '../../util/dropdownOptions';
import {
    clientTypeId,
    tipoIdentificacionComercial,
    tipoIdentificacionPersonal,
    preferredBranchId,
    leadChannel,
} from '../commons';
import secondaryClientForm from "../secondary/CaseIntakeSchema";


const form = {
    title: "Crear Caso",
    displayName: 'CaseIntake',
    page: 'case-intake',
    secondaryClientForm,
    canAddClients: true,
    hasSaveProgressButton: ({ auth }) => (auth.isAdmin)
};


const comercialSchema = {
    title: "Información Inicial Cliente Empresa",
    type: "object",
    properties: {
        "nombreEmpresa": {
            title: "Nombre Empresa",
            type: "string",
        },
        "actividadPrincipal": {
            title: "Actividad Principal",
            type: "string",
            enum: dropdownOptions.actividades,
        },
        "tipoIdentificacionComercial": {
            title: "Tipo de Identificación",
            type: "number",
            oneOf: [
                { title: "RNC", const: 4 },
            ],
            default: 4
        },
        "numIdentificacionRNC": {
            title: "No. de identificación RNC",
            type: "string",
            minLength: 9,
            maxLength: 9,
        },
        "nombreContacto": {
            title: "Nombre Contacto",
            type: "string",
        },
        "apellidoContacto": {
            title: "Apellido Contacto",
            type: "string",
        },
        "posicionContacto": {
            title: "Posición Contacto",
            type: "string",
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        "comentarios": leadChannel
    },
    required: ["nombreEmpresa", "actividadPrincipal", "tipoIdentificacionComercial",
        "nombreContacto", "apellidoContacto",
        "posicionContacto", "celular", "email", "numIdentificacionRNC"
    ],
    dependencies: {
    },
}

const clientSchema = {
    title: "Información Cliente Titular",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombre",
            type: "string",
        },
        "segundoNombre": {
            title: "Segundo Nombre",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        tipoIdentificacionPersonal,
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "otro": {
            title: "Otro",
            type: "string",
            format: "phone-rd",
        },
        "comentarios": leadChannel
    },
    required: ["nombre", "apellidos", "email", "tipoIdentificacionPersonal"
        , "sexo", "estadoCivil",
        "celular"
    ],
    dependencies: {
        "tipoIdentificacionPersonal": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 2,
                        },
                        "numIdentificacionPasaporte": {
                            title: "No. de identificación Pasaporte",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionPasaporte"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 3,
                        },
                        "numIdentificacionIdExtranjero": {
                            title: "No. de identificación Id Extranjero",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionIdExtranjero"]
                },
            ],
        }
    },
}

const smbCedulaSchema = {
    title: "Información Cliente Titular",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        "tipoIdentificacionPersonal": {
            title: "Tipo de Identificación",
            type: "number",
            oneOf: [
                { title: "Cédula", const: 1 },
            ],
            default: 1
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "otro": {
            title: "Otro",
            type: "string",
            format: "phone-rd",
        },
        "comentarios": leadChannel
    },
    required: ["nombre", "apellidos", "email", "tipoIdentificacionPersonal"
        , "sexo", "estadoCivil",
        "celular"
    ],
    dependencies: {
        "tipoIdentificacionPersonal": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
            ],
        }
    },
}

const assignmentsSchema ={
    title: "Designaciones",
    type: "object",
    properties: {
        "asesorFinanciero": {
            title: "Asesor Financiero / Gerente",
            type: "number",
            lookup: {
                api: 'users',
                params: {
                    roleDef: 'financialAdvisor|customerRelationshipManager|operationsManager|creditManager|customerRelationshipMarketManager',
                    client: { field: 'clientTypeId' },
                    branch: { field: 'preferredBranchId' },
                    ignoreClientType: true,
                    sortBy: 'name'
                },
                resource: "Usuarios",
                id: 'id', label: 'completeName'
            }
        },
        "oficialRelaciones": {
            title: "Oficial de Relaciones",
            type: "number",
            lookup: {
                api: 'users',
                params: {
                    roleDef: 'relationshipOfficer',
                    client: { field: 'clientTypeId' },
                    branch: { field: 'preferredBranchId' },
                    ignoreClientType: true,
                    sortBy: 'name'
                },
                resource: "Usuarios",
                id: 'id', label: 'completeName'
            }
        }
    }
}

form.schema = {
    title: "Solicitud de Vinculación Cliente",
    type: "object",
    properties: {
        clientTypeId,
        "tipoVinculacion": {
            title: "¿Tipo de Vinculación?",
            type: "string",
            oneOf: [
                {
                    title: "Cliente Nuevo JMMB",
                    const: "Cliente Nuevo JMMB"
                },
                {
                    title: "Vinculación a filial adicional",
                    const: "Vinculacion a filial adicional"
                },
                {
                    title: "Cliente existente solo Solicitud de Préstamo",
                    const: "Cliente existente solo Solicitud de Prestamo"
                },
            ],
        },
        "duracionRelacion": {
            title: "Duración de la relación",
            type: "string",
            enumNames: [
                "Cliente Nuevo ( Menos de 1 año)",
                "Más de 1 año menos de 3 años",
                "Más de 3 años menos de 5 años",
                "Más de 5 años"
            ],
            enum: [
                "Cliente Nuevo ( Menos de 1 ano)",
                "Mas de 1 ano menos de 3 anos",
                "Mas de 3 anos menos de 5 anos",
                "Mas de 5 anos"
            ],
        },
        
    },
    required: ["clientTypeId", "tipoVinculacion", "preferredBranchId"],
    dependencies: {
        "clientTypeId": {
            oneOf: [
                {
                    properties: {
                        "clientTypeId": { const: 3 }, // Comercial
                        "comercialSchema": comercialSchema,
                        "preferredBranchId": preferredBranchId,
                    },
                    dependencies: {
                        "preferredBranchId":{
                            oneOf: [
                                {
                                    properties: {
                                        "preferredBranchId": {
                                            // If any branch is selected, show assignmentsSchema
                                        },
                                        "assignmentsSchema": assignmentsSchema,
                                    }
                                },
                            ]
                        }
                    }       
                },
                {
                    properties: {
                        "clientTypeId": { const: 2 }, // Pyme - RNC
                        "comercialSchema": comercialSchema,
                        "preferredBranchId": preferredBranchId,
                    },
                    dependencies: {
                        "preferredBranchId":{
                            oneOf: [
                                {
                                    properties: {
                                        "preferredBranchId": {
                                            // If any branch is selected, show assignmentsSchema
                                        },
                                        "assignmentsSchema": assignmentsSchema,
                                    }
                                },
                            ]
                        }
                    }
                },
                {
                    properties: {
                        "clientTypeId": { const: 4 }, // Pyme - Cedula
                        "clientSchema": smbCedulaSchema,
                        "preferredBranchId": preferredBranchId,
                    },
                    dependencies: {
                        "preferredBranchId":{
                            oneOf: [
                                {
                                    properties: {
                                        "preferredBranchId": {
                                            // If any branch is selected, show assignmentsSchema
                                        },
                                        "assignmentsSchema": assignmentsSchema,
                                    }
                                },
                            ]
                        }
                    }
                },
                {
                    properties: {
                        "clientTypeId": { const: 1 }, // Personal
                        "vinculacionMancomunada": {
                            title: "¿Solicitud de vinculación mancomunada?",
                            type: "boolean",
                            oneOf: [
                                { title: "Si", const: true },
                                { title: "No", const: false },
                            ],
                            default: false,
                        },
                        "clientSchema": clientSchema,
                        "preferredBranchId": preferredBranchId,
                    },
                    dependencies: {
                        "preferredBranchId":{
                            oneOf: [
                                {
                                    properties: {
                                        "preferredBranchId": {
                                            // If any branch is selected, show assignmentsSchema
                                        },
                                        "assignmentsSchema": assignmentsSchema,
                                    }
                                },
                            ]
                        }
                    },
                    required: ["vinculacionMancomunada"],
                }
            ]
        },
    }
};


form.uiSchema = {
    "ui:order": [
        "clientTypeId",
        "tipoVinculacion",
        "duracionRelacion",
        "preferredBranchId",
        "*",
        "assignments"
    ],
    "preferredBranchId": { "ui:field": "LookupFormField" },
    "clientTypeId": {
        "ui:widget": "radio",
    },
    "tipoVinculacion": {
        "ui:widget": "radio",
    },
    "vinculacionMancomunada": {
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "comercialSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nombreEmpresa",
            "actividadPrincipal",
            "nombreContacto",
            "apellidoContacto",
            "posicionContacto",
            "email",
            "tipoIdentificacionComercial",
            "numIdentificacionRNC",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "classNames": "from-col-1",
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionComercial": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivilRepresentante": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "clientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nombre",
            "segundoNombre",
            "apellidos",
            "email",
            "tipoIdentificacionPersonal",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionPersonal": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "buscarDataPersonal":{
            "ui:field": "FetchClientInfoButton",
        },
        "sexo": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "secondClientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "*",
            "tipoIdentificacionPersonal",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionPersonal": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "loanComercialSchema": {
        "local": {
            "ui:widget": "radio",
        },
    },
    "assignmentsSchema": {
        "classNames": "smallTwoRows",
        "ui:newSection": true,
        "ui:field": "HideObjectField",
        "asesorFinanciero": {
            "ui:field": "LookupFormField", 'joli:hideAttr': {
                'formContext': {
                    'user.role.name': [
                        'financial-advisor-retail-bank',
                        'financial-advisor-sme-bank',
                        'financial-advisor-corporate-bank',
                        'financial-advisor-retail-puesto',
                        'financial-advisor-sme-puesto',
                        'financial-advisor-corporate-puesto',
                        'financial-advisor-retail-safi',
                        'financial-advisor-sme-safi',
                        'financial-advisor-corporate-safi',
                        'customer-relationship-manager-retail',
                        'customer-relationship-manager-sme',
                        'customer-relationship-manager-corporate',
                        'operations-manager-puesto',
                        'operations-manager-banco',
                        'credit-manager',
                        'customer-relationship-market-manager-retail',
                        'customer-relationship-market-manager-sme',
                        'customer-relationship-market-manager-corporate',
                    ]
                }
            }
        },
        "oficialRelaciones": {
            "ui:field": "LookupFormField", 'joli:hideAttr': {
                'formContext': {
                    'user.role.name': [
                        'relationship-officer-retail-bank',
                        'relationship-officer-sme-bank',
                        'relationship-officer-corporate-bank',
                        'relationship-officer-retail-puesto',
                        'relationship-officer-sme-puesto',
                        'relationship-officer-corporate-puesto',
                        'relationship-officer-retail-safi',
                        'relationship-officer-sme-safi',
                        'relationship-officer-corporate-safi',
                    ]
                }
            }
        }
    }
}

form.navigation = [
    {
        title: "Solicitud de Vinculación Cliente",
        id: ""
    },
    {
        title: "Información Inicial Cliente Empresa",
        id: "comercialSchema_"
    },
    {
        title: "Información Inicial Cliente Empresa",
        id: "comercialSchema_"
    },
]


form.objectMap = {
    "isJointly": "vinculacionMancomunada",
    "clients[0].clientTypeId": "clientTypeId",
    "clients[0].preferredBranchId": "preferredBranchId",
    "clients[0].relationshipType": "tipoVinculacion",
    "clients[0].relationshipDuration": "duracionRelacion",

    // BUSINESS
    "clients[0].businessClientBasicInfo.businessName": "comercialSchema.nombreEmpresa",
    "clients[0].businessClientBasicInfo.businessMainActivity": "comercialSchema.actividadPrincipal",
    "clients[0].businessClientBasicInfo.identificationType": "comercialSchema.tipoIdentificacionComercial",
    "clients[0].businessClientBasicInfo.contactFirstName": "comercialSchema.nombreContacto",
    "clients[0].businessClientBasicInfo.contactLastName": "comercialSchema.apellidoContacto",
    "clients[0].businessClientBasicInfo.contactPosition": "comercialSchema.posicionContacto",
    "clients[0].businessClientBasicInfo.phone": "comercialSchema.telefono",
    "clients[0].businessClientBasicInfo.phoneMobile": "comercialSchema.celular",
    "clients[0].businessClientBasicInfo.phoneOther": { const: "" },
    "clients[0].businessClientBasicInfo.contactEmail": "comercialSchema.email",
    "clients[0].businessClientBasicInfo.comments": "comercialSchema.comentarios",
    "clients[0].businessClientBasicInfo.identificationNumber": "comercialSchema.numIdentificacionRNC",

    // RETAIL
    "clients[0].retailClientBasicInfo.firstName": "clientSchema.nombre",
    "clients[0].retailClientBasicInfo.secondName": "clientSchema.segundoNombre",
    "clients[0].retailClientBasicInfo.lastName": "clientSchema.apellidos",
    "clients[0].retailClientBasicInfo.email": "clientSchema.email",
    "clients[0].retailClientBasicInfo.identificationType": "clientSchema.tipoIdentificacionPersonal",
    "clients[0].retailClientBasicInfo.identificationNumber": "clientSchema.numIdentificacionCedula",
    "clients[0].retailClientBasicInfo.passportNumber": "clientSchema.numIdentificacionPasaporte",
    "clients[0].retailClientBasicInfo.foreignIdNumber": "clientSchema.numIdentificacionIdExtranjero",
    "clients[0].retailClientBasicInfo.sex": "clientSchema.sexo",
    "clients[0].retailClientBasicInfo.civilStatus": "clientSchema.estadoCivil",
    "clients[0].retailClientBasicInfo.phone": "clientSchema.telefono",
    "clients[0].retailClientBasicInfo.phoneMobile": "clientSchema.celular",
    "clients[0].retailClientBasicInfo.phoneOther": "clientSchema.otro",

    "clients[0].retailClientBasicInfo.comments": "clientSchema.comentarios",
    "assign.fa": "assignmentsSchema.asesorFinanciero",
    "assign.ro": "assignmentsSchema.oficialRelaciones",
}


export default form;