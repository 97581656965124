import React, { useMemo, useState } from "react";
import { FormGroup, Button } from "reactstrap";
import CasesApi from "../../api/CasesApi";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import Loader from '../Loader';
import Notification from "../Notification";


function FetchClientInfoButton(props) {
    const {
        formContext,
        schema: {
            title,
        },
        uiSchema: {
            'ui:readonly': readonly
        },
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [newError, setError] = useState("");
    const { sideChannel } = formContext;
    const formObject = useSideChannelSubscription(sideChannel, 1) || [{}];
    async function fetchData(e){
        e.preventDefault();
        if(isLoading) return;
        const initialInfo = formObject.clients[0].retailClientBasicInfo
        const idType = initialInfo.identificationType;
        const idString = idType === 1 && initialInfo.identificationNumber ? initialInfo.identificationNumber :
                         idType === 2 && initialInfo.passportNumber ? initialInfo.passportNumber :
                         idType === 3 && initialInfo.foreignIdNumber ? initialInfo.foreignIdNumber : ""
        if(idString === "") return;
        setIsLoading(true);
        try {
            setError("");
            const data = await CasesApi.getClientBasicInfo({idString, idType});
            formContext.setFormDataValues({
                "clientSchema.nombre":data.clients[0].retailClientBasicInfo.firstName,
                "clientSchema.apellidos":data.clients[0].retailClientBasicInfo.lastName,
                "clientSchema.segundoNombre":data.clients[0].retailClientBasicInfo.secondName,
                "clientSchema.email":data.clients[0].retailClientBasicInfo.email,
                "clientSchema.sexo":data.clients[0].retailClientBasicInfo.sex,
                "clientSchema.telefono":data.clients[0].retailClientBasicInfo.phone,
                "clientSchema.celular":data.clients[0].retailClientBasicInfo.phoneMobile,
                "clientSchema.otro":data.clients[0].retailClientBasicInfo.phoneOther,
                "clientSchema.estadoCivil":data.clients[0].retailClientBasicInfo.civilStatus,
                "clientSchema.comentarios":data.clients[0].retailClientBasicInfo.comments,
                // InfoAdicional
                "clientAditionalInfoSchema.fechaNacimiento":data.clients[0].retailClientBasicInfo.dateOfBirth,
                "clientAditionalInfoSchema.lugarNacimiento":data.clients[0].retailClientBasicInfo.countryOfBirth,
                "clientAditionalInfoSchema.nacionalidad":data.clients[0].retailClientBasicInfo.nationality,
                "clientAditionalInfoSchema.direccionResidencia":data.clients[0].retailClientBasicInfo.address,
                "clientAditionalInfoSchema.sector":data.clients[0].retailClientBasicInfo.addressSector,
                "clientAditionalInfoSchema.ciudad":data.clients[0].retailClientBasicInfo.city,
                "clientAditionalInfoSchema.pais":data.clients[0].retailClientBasicInfo.country,
                "clientAditionalInfoSchema.residenteRD":data.clients[0].retailClientBasicInfo.residentOfDominicanRepublic,
                "clientAditionalInfoSchema.residenciaFisicaUSA":data.clients[0].retailClientBasicInfo.isAmericanResident,
                "clientAditionalInfoSchema.estatusMigratorio":data.clients[0].retailClientFatcaInfo.unitedStatesImmigrationStatus,
                "clientAditionalInfoSchema.profesion":data.clients[0].retailClientBasicInfo.profession,
                "clientAditionalInfoSchema.ocupacion":data.clients[0].retailClientBasicInfo.occupation,
                "clientAditionalInfoSchema.ingresosMensuales":data.clients[0].retailClientBasicInfo.monthlyIncome,
                "clientAditionalInfoSchema.zonaGeograficaNacional":data.clients[0].retailClientBasicInfo.nationalGeographicZone,
                "clientAditionalInfoSchema.personaExpuestaPoliticamente":data.clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposure,
                "clientAditionalInfoSchema.parentescoPersonaExpuestaPoliticamente":data.clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposureRelative,
                "clientAditionalInfoSchema.sujetoObligadoLey155":data.clients[0].retailClientBasicInfo.isSubjectToLaw15517,
                "clientAditionalInfoSchema.vinculadoJMMB":data.clients[0].retailClientBasicInfo.isRelatedToFranchise,
                "clientAditionalInfoSchema.beneficiariosDeTransaccion":data.clients[0].retailClientBasicInfo.hasBeneficiaries,
                "clientAditionalInfoSchema.nombreBeneficiario":data.clients[0].retailClientBasicInfo.beneficiaryName,
                "clientAditionalInfoSchema.ocupacionOtro":data.clients[0].retailClientBasicInfo.otherOccupation,
                "clientAditionalInfoSchema.indicarPaises":data.clients[0].retailClientBasicInfo.otherNationalities,
                "clientAditionalInfoSchema.nacionalidadOtroPais":data.clients[0].retailClientBasicInfo.hasOtherNationality,
                "clientAditionalInfoSchema.tipoSujetoObligado":data.clients[0].retailClientBasicInfo.obligationType,
                "clientAditionalInfoSchema.ingresosActividadesFormales":data.clients[0].retailClientBasicInfo.isIncomeFromFormalActivities,
                "clientAditionalInfoSchema.otraFuenteIngresosFormales":data.clients[0].retailClientBasicInfo.hasOtherSourcesOfIncome,
                "clientAditionalInfoSchema.idBeneficiario":data.clients[0].retailClientBasicInfo.beneficiaryId,
                "clientAditionalInfoSchema.apellidoBeneficiario":data.clients[0].retailClientBasicInfo.beneficiaryLastName,
            })
        } catch (error) {
            setIsLoading(false);
            if(error.message === "Not Found"){
                setError("No se encontró cliente con este ID.");
            }
            else{
                setError(error.message);
            }
            
        }
        setIsLoading(false);
    }

    return (
        <>
            {newError ? (<Notification color="danger">
            <div>{newError}</div>
            </Notification>) : null}
            {!readonly ? <FormGroup className="file-field" disabled={readonly || isLoading}><Button
                className="btn btn-secondary"
                target="_blank"
                onClick={(e) => fetchData(e)}
            >{isLoading ? <Loader/> : title}</Button></FormGroup> : <></>}
        </>
        
    );
}


export default FetchClientInfoButton;