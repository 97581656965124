import dropdownOptions from '../../util/dropdownOptions';
import {
    clientTypeId,
    tipoIdentificacionPersonal,
    preferredBranchId,
    boolYesNoType,
    leadChannel,
    contractSigner
} from '../commons';
import secondaryClientForm from '../secondary/ShortKYCPersonalClientSchema';

const form = {
    title: "Orientación",
    displayName: 'ShortKYCPersonalClient',
    page: 'short-kyc-personal',
    commentFieldsMap: {
        "Facilidades Solicitadas": "caseLoanInfo.comments",
    },
    canAddClients: true,
    secondaryClientForm
};


const prestamosSchema = {
    title: "Solicitud Préstamo",
    type: "object",
    properties: {
        "montoPrestamo": {
            title: "Monto deseado de préstamo",
            type: "number",
        },
        "destinoFondos": {
            title: "Destino de los fondos",
            type: "string",
        }
    },
}

const solicitudPrestamoSchema = {
    title: " ",
    type: "object",
    properties: {
        "facilidadesSolicitadas": {
            title: " ",
            description: "Facilidades solicitadas",
            type: "object",
            properties: {
                "proposito": {
                    title: "Propósito",
                    type: "string",
                },
                "descripcionFacilidades": {
                    title: " ",
                    description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
                    type: "array",
                    maxItems: 5,
                    items: {
                        type: "object",
                        properties: {
                            "numFacilidad": {
                                title: "No. de Facilidad",
                                type: "string",
                            },
                            "descripcion": {
                                title: "Descripción",
                                type: "string",
                            },
                            "tipoFacilidad": {
                                title: "Tipo de Facilidad",
                                type: "string",
                                enumNames: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nómina",
                                    "Hipotecario",
                                ],
                                enum: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nomina",
                                    "Hipotecario",
                                ],
                            },
                            "montoAprobado": {
                                title: "Monto Aprobado (si aplica)",
                                type: "string",
                            },
                            "montoUtilizado": {
                                title: "Monto Utilizado (si aplica)",
                                type: "string",
                            },
                            "montoPropuesto": {
                                title: "Monto Propuesto",
                                type: "string",
                            },
                            "tasa": {
                                title: "Tasa",
                                type: "number",
                            },
                            "formaPago": {
                                title: "Forma de Pago",
                                type: "string",
                                enumNames: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                                enum: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                            },
                            "cuotaEstimada": {
                                title: "Cuota Estimada",
                                type: "string",
                            },
                            "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
                        },
                    },
                },
                "incluyeGarantia": { title: "¿Incluye garantía?", ...boolYesNoType },
                "comentarios": {
                    title: "Comentarios",
                    type: "string",
                }
            },
            dependencies: {
                "incluyeGarantia": {
                    allOf: [{
                        if: { properties: { "incluyeGarantia": { const: true } } },
                        then: {
                            properties: {
                                "incluyeGarantiaSolidaria": { title: "¿Incluye garantía solidaria?", ...boolYesNoType },
                                "descGarantias": {
                                    title: "Descripción de garantía",
                                    type: "object",
                                    properties: {
                                        "noDeGarantia": {
                                            title: "No. de Garantía",
                                            type: "string",
                                        },
                                        "noFacilidadAsociada":
                                        {
                                            title: "No. Facilidad asociada",
                                            type: "string"
                                        },
                                        "tipoDeGarantia": {
                                            title: " ",
                                            description: "Tipo de Garantía",
                                            type: "array",
                                            items: {
                                                type: "number",
                                                oneOf: [
                                                    { title: "Garantía Solidaria", const: 1 },
                                                    { title: "Descuento de Nómina", const: 2 },
                                                    { title: "Hipotecario", const: 3 },
                                                    { title: "Prendaria", const: 4 },
                                                    { title: "Certificado Financiero", const: 5 },
                                                    { title: "Vehículo", const: 6 },
                                                    { title: "Otra", const: 7 },
                                                ],
                                            },
                                            minItems: 1,
                                            uniqueItems: true,
                                            
                                        },
                                        "descGarantia":
                                        {
                                            title: "Descripción de garantía",
                                            type: "string",
                                        },
                                        "valGarantia":
                                        {
                                            title: "Valor de Garantía en Tasación",
                                            type: "string",
                                        },
                                    },
                                    dependencies: {
                                        "tipoDeGarantia": {
                                            allOf: [
                                                {
                                                    if: {
                                                        properties: { "tipoDeGarantia":{ contains: { const: 7 } }},
                                                    },
                                                    then: {
                                                        properties :{
                                                            "contratoElaboradoPor": contractSigner
                                                        },
                                                        required:["contratoElaboradoPor"]
                                                    }
                                                }
                                            ]
                                        },
                                    }
                                },
                            }
                        }
                    }]
                },
            }
        },
    },
}
const financialCertSchema = {
    title: "Certificado Financiero",
    type: "object",
    properties: {
        "inversionEstimadaRD": {
            title: "Monto estimado inversión en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        }, required: ["origenFondosRDOtro"]

                    }
                }
            ]
        },
    },
    required: ["inversionEstimadaRD", "origenFondosRD", "tasaCambio"]
}

const savingsAccountSchema = {
    title: " ",
    description: "Cuenta de Ahorro",
    type: "object",
    properties: {
        "rdCurrency": {
            title: "RD$",
            type: "boolean",
        },
        "usCurrency": {
            title: "US$",
            type: "boolean",
        }
    },
    dependencies:{
        "rdCurrency":{
            oneOf: [
                {
                    properties: {
                        "rdCurrency": {
                            const: true
                        },
                        "depositoEstimadoRD": {
                            title: "Monto estimado primer depósito en RD",
                            type: "number",
                        },
                        "origenFondosRD": {
                            title: "Origen de fondos en RD",
                            type: "string",
                            enumNames: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehículo",
                                "Liquidación de otra inversión",
                                "Otro",
                                "No aplica"
                            ],
                            enum: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehiculo",
                                "Liquidacion de otra inversion",
                                "Otro",
                                "No aplica"
                            ],
                        },
                    },
                    dependencies: {
                        "origenFondosRD": {
                            allOf: [
                                {
                                    if: {
                                        properties: {
                                            "origenFondosRD": {
                                                const: "Otro",
                                            },
                                        }
                                    },
                                    then: {
                                        properties: {
                                            "origenFondosRDOtro": {
                                                title: "Otro",
                                                type: "string"
                                            },
                                        },
                                        required: ["origenFondosRDOtro"]
                                    }
                                }
                            ]
                        },
                    },
                    required: ["depositoEstimadoRD", "origenFondosRD"]
                },
                {
                    properties: {
                        "rdCurrency": {
                            const: false
                        },
                    }
                }    
            ]
        },
        "usCurrency":{
            oneOf: [
                {
                    properties: {
                        "usCurrency": {
                            const: true
                        },
                        "depositoEstimadoUSD": {
                            title: "Monto estimado primer depósito en USD",
                            type: "number",
                        },
                        "origenFondosUSD": {
                            title: "Origen de fondos en USD",
                            type: "string",
                            enumNames: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehículo",
                                "Liquidación de otra inversión",
                                "Otro",
                                "No aplica"
                            ],
                            enum: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehiculo",
                                "Liquidacion de otra inversion",
                                "Otro",
                                "No aplica"
                            ],
                        },
                    },
                    dependencies: {
                        "origenFondosUSD": {
                            allOf: [
                                {
                                    if: {
                                        properties: {
                                            "origenFondosUSD": {
                                                const: "Otro",
                                            },
                                        }
                                    },
                                    then: {
                                        properties: {
                                            "origenFondosUSDOtro": {
                                                title: "Otro",
                                                type: "string"
                                            },
                                        },
                                        required: ["origenFondosUSDOtro"]
                                    }
                                }
                            ]
                        },
                    },
                    required: ["depositoEstimadoUSD", "origenFondosUSD"]
                },
                {
                    properties: {
                        "usCurrency": {
                            const: false
                        },
                    }
                }    
            ]
        }
    }
}

const inversionesSchema = {
    title: " ",
    description: "Inversiones en Puesto",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión Puesto en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para Puesto en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Puesto en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para Puesto en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const safiSchema = {
    title: " ",
    description: "Inversiones en SAFI",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión SAFI en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para SAFI en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión SAFI en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para SAFI en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}


const kycPersonalClientSchema = {
    title: " ",
    type: "object",
    properties: {
        "institucionVincularse": {
            title: "Institución a vincularse",
            type: "array",
            items: {
                type: "number",
                oneOf: [
                    { title: "Banco", const: 1 },
                    { title: "Puesto", const: 2 },
                    { title: "SAFI", const: 3 },
                ],
            },
            minItems: 1,
            uniqueItems: true,
        },

    },
    dependencies: {
        "institucionVincularse": {
            allOf: [
                {
                    if: {
                        properties: { "institucionVincularse": { contains: { const: 1 } } }
                    },
                    then: {
                        properties: {
                            "productosBanco": {
                                title: "Productos de interés Banco",
                                type: "array",
                                lookup: {
                                    api: 'products/1',
                                    params: {
                                        clientType: { field: 'clientTypeId' }
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                items: { type: "number" },
                                minItems: 1,
                                uniqueItems: true,
                            },
                        },
                        dependencies: {
                            "productosBanco": {
                                allOf: [
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 1 } } // "Cuenta de Ahorro"
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "cuentaAhorroBasica": { title: "¿Es Cuenta de Ahorro Básica?", ...boolYesNoType },
                                                "savingsAccountSchema": savingsAccountSchema,
                                            },
                                            dependencies: {
                                                "cuentaAhorroBasica": {
                                                    oneOf: [{
                                                        properties: {
                                                            "cuentaAhorroBasica": { const: true },
                                                            "soloMantieneEstaCuenta": { title: "¿Cliente solo mantiene esta cuenta?", ...boolYesNoType }
                                                        }
                                                    },
                                                    {
                                                        properties: {
                                                            "cuentaAhorroBasica": { const: false },
                                                        }
                                                    }]
                                                }
                                            }
                                        },
                                        else: {
                                            if: {
                                                properties: {
                                                    "productosBanco": { contains: { const: 17 } },  // Préstamo Comercial (PYME - DNI (Cédula))
                                                }
                                            },
                                            else: {
                                                properties: {
                                                    "excepcionCuentaAhorro": {
                                                        title: "Razón por la que no desea cuenta de ahorro:",
                                                        type: "string",
                                                        enum: ["Ya posee cuenta en JMMB", "No desea el producto"]
                                                    },
                                                }
                                            }
                                        }
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": {
                                                    contains: { const: 3 } // "Certificado Financiero"
                                                },
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "financialCertSchema": financialCertSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 4 } }, // Préstamo de Consumo
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 5 } }, // "Préstamo Hipotecario"
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 6 } },  // Préstamo Vehículo
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 7 } },  // Línea de Crédito
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 17 } },  // Préstamo Comercial (PYME - DNI (Cédula))
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                ]
                            }
                        }
                    },
                    else: {
                        properties: {
                            "excepcionCuentaAhorro": {
                                title: "Razón por la que no desea cuenta de ahorro:",
                                type: "string",
                                enum: ["Ya posee cuenta en JMMB", "No desea el producto"]
                            },
                        }
                    }
                },
                {
                    if: {
                        properties: { "institucionVincularse": { contains: { const: 2 } } }
                    },
                    then: {
                        properties: {
                            "productosPuesto": {
                                title: "Productos de interés Puesto",
                                type: "array",
                                lookup: {
                                    api: 'products/2',
                                    params: {
                                        clientType: { field: 'clientTypeId' }
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                minItems: 1,
                                items: { type: "number" },
                                uniqueItems: true,
                            },
                            "inversionesSchema": inversionesSchema,
                        }
                    }
                },
                {
                    if: {
                        properties: { "institucionVincularse": { contains: { const: 3 } } }
                    },
                    then: {
                        properties: {
                            "productosSAFI": {
                                title: "Productos de interés SAFI",
                                type: "array",
                                lookup: {
                                    api: 'products/3',
                                    params: {
                                        clientType: { field: 'clientTypeId' }
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                minItems: 1,
                                items: { type: "number" },
                                uniqueItems: true,
                            },
                            "safiSchema": safiSchema,
                        }
                    }
                },
            ]
        }
    }
}

const clientSchema = {
    title: "Información Cliente Titular",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombre",
            type: "string",
        },
        "segundoNombre": {
            title: "Segundo Nombre",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        tipoIdentificacionPersonal,
        "sexo": {
            title: "Sexo",
            type: "string",
            enum: ["Masculino", "Femenino"],
            // oneOf: [
            //     { title: "Masculino", const: 1 },
            //     { title: "Femenino", const: 2 },
            // ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            enumNames: ["Soltero", "Casado", "Unión Libre"],
            enum: ["Soltero", "Casado", "Union Libre"]
            // oneOf: [
            //     { title: "Soltero", const: 1 },
            //     { title: "Casado", const: 2 },
            //     { title: "Unión Libre", const: 3 },
            // ],
        },
        "celular": {
            title: "Celular",
            type: "string",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
        },
        "otro": {
            title: "Otro",
            type: "string",
        },
        "comentarios": leadChannel
    },
    // required: ["nombre", "apellidos", "email", "tipoIdentificacion",
    //     "numIdentificacion", "sexo", "estadoCivil",
    //     "celular"
    // ],
    dependencies: {
        "tipoIdentificacionPersonal": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",

                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 2,
                        },
                        "numIdentificacionPasaporte": {
                            title: "No. de identificación Pasaporte",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionPasaporte"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 3,
                        },
                        "numIdentificacionIdExtranjero": {
                            title: "No. de identificación Id Extranjero",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionIdExtranjero"]
                },
            ],
        }
    },
}

const smbCedulaSchema = {
    title: "Información Cliente Titular",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
            format: "email",
        },
        "tipoIdentificacionPersonal": {
            title: "Tipo de Identificación",
            type: "number",
            oneOf: [
                { title: "Cédula", const: 1 },
            ],
            default: 1
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "otro": {
            title: "Otro",
            type: "string",
            format: "phone-rd",
        },
        "comentarios": leadChannel
    },
    required: ["nombre", "apellidos", "email", "tipoIdentificacionPersonal"
        , "sexo", "estadoCivil",
        "celular"
    ],
    dependencies: {
        "tipoIdentificacionPersonal": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
            ],
        }
    },
}

const clientAditionalInfoSchema = {
    title: " ",
    type: "object",
    properties: {
        "fechaNacimiento": {
            title: "Fecha de Nacimiento",
            type: "string",
            format: "date",
        },
        "lugarNacimiento": {
            title: "Lugar de Nacimiento",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidad": {
            title: "Nacionalidad",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidadOtroPais": { title: "¿Tiene nacionalidad o ciudadanía de otro país?", ...boolYesNoType },
        "direccionResidencia": {
            title: "Dirección de residencia",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "residenteRD": { title: "¿Es residente de la República Dominicana?", ...boolYesNoType, default: true },
        "residenciaFisicaUSA": { title: "¿Es Estados Unidos su país de residencia física?", ...boolYesNoType },
        "zonaGeograficaNacional": {
            title: "Zona Geografica Nacional",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional
        },
        "estatusMigratorio": {
            title: "¿Tiene algún estatus migratorio en los Estados Unidos?",
            type: "string",
            enumNames: ["No", "Ciudadanía", "Residencia Fiscal", "Residencia Permanente"],
            enum: ["No", "Ciudadania", "Residencia Fiscal", "Residencia Permanente"],
            // oneOf: [
            //     {
            //         title: "No",
            //         const: 1
            //     },
            //     {
            //         title: "Ciudadanía",
            //         const: 2
            //     },
            //     {
            //         title: "Residencia Fiscal",
            //         const: 3
            //     },
            //     {
            //         title: "Residencia Permanente",
            //         const: 4
            //     },
            // ],
        },
        "profesion": { title: "Profesión", type: "string" },
        "ocupacion": {
            title: "Ocupación",
            type: "string",
            enumNames: ["Empleado Privado", "Empleado Público", "Independiente", "Retirado", "Pensionado","Vehículo", "Otro"],
            enum: ["Empleado Privado", "Empleado Publico", "Independiente", "Retirado", "Pensionado","Vehiculo", "Otro"],
            // anyOf: [
            //     { title: "Empleado Privado", const: 1 },
            //     { title: "Empleado Público", const: 2 },
            //     { title: "Independiente", const: 3 },
            //     { title: "Retirado", const: 4 },
            //     { title: "Pensionado", const: 5 },
            //     { title: "Otro", const: 6 },
            // ],
        },
        "ingresosMensuales": {
            title: "Ingresos Mensuales",
            type: "number",
        },
        "sujetoObligadoLey155": { title: "¿Es Sujeto Obligado de la Ley 155-17?", ...boolYesNoType },
        "personaExpuestaPoliticamente": { title: "¿Es o ha sido una persona expuesta políticamente (PEP)?", ...boolYesNoType },
        "parentescoPersonaExpuestaPoliticamente": {
            title: "¿Tiene algún parentesco, es asociado cercano o realiza una operación en nombre de una persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
        "beneficiariosDeTransaccion": {
            title: "¿Existen beneficiarios directos o indirectos de la transaccion diferentes de usted?",
            ...boolYesNoType
        },
        "vinculadoJMMB": { title: "¿Es vinculado o relacionado a un vinculado de JMMB?", ...boolYesNoType },
    },
    dependencies: {
        "nacionalidadOtroPais": {
            allOf: [{
                if: {
                    properties: {
                        "nacionalidadOtroPais": { const: true },
                    }
                },
                then: {
                    properties: {
                        "indicarPaises": { title: "Indicar Países", type: "string" }
                    }
                },
            }]
        },
        "ocupacion": {
            allOf: [
                {
                    if: {
                        properties: {
                            "ocupacion": { const: "Independiente" },
                        }
                    },
                    then: {
                        properties: {
                            "inscritoCedulaAnteDGII": {
                                title: "¿Está inscrito con su cédula ante la DGII?",
                                ...boolYesNoType
                            },
                            "otraFuenteIngresosFormales": {
                                title: "¿Tiene otras fuentes de ingresos formales?",
                                ...boolYesNoType
                            },
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "ocupacion": { const: "Otro", },
                        }
                    },
                    then: {
                        properties: {
                            "ocupacionOtro": { title: "Otro", type: "string" },
                            "inscritoCedulaAnteDGII": {
                                title: "¿Está inscrito con su cédula ante la DGII?",
                                ...boolYesNoType
                            },
                            "otraFuenteIngresosFormales": {
                                title: "¿Tiene otras fuentes de ingresos formales?",
                                ...boolYesNoType
                            },
                        }
                    },
                },
            ]
        },
        "beneficiariosDeTransaccion": {
            allOf: [{
                if: {
                    properties: {
                        "beneficiariosDeTransaccion": { const: true },
                    }
                },
                then: {
                    properties: {
                        "nombreBeneficiario": { title: "Nombre del beneficiario", type: "string" },
                        "apellidoBeneficiario": { title: "Apellido del beneficiario", type: "string" },
                        "idBeneficiario": { title: "Número de identificación del beneficiario", type: "number" },
                    }
                },
            }]
        },
    },
    required: [
        "fechaNacimiento", "lugarNacimiento", "nacionalidad", "nacionalidadOtroPais", "direccionResidencia",
        "sector", "ciudad", "pais", "residenteRD", "residenciaFisicaUSA", "zonaGeograficaNacional",
        "estatusMigratorio", "profesion", "ocupacion", "ingresosMensuales", "sujetoObligadoLey155",
        "personaExpuestaPoliticamente", "parentescoPersonaExpuestaPoliticamente",
        "beneficiariosDeTransaccion", "vinculadoJMMB",
    ],
}

form.schema = {
    title: "Solicitud de Vinculación Cliente Personal",
    type: "object",
    properties: {
        clientTypeId,
        preferredBranchId,
    },
    // required: ["clientTypeId", "preferredBranchId"],
    dependencies: {
        "clientTypeId": {
            oneOf: [
                {
                    properties: {
                        "clientTypeId": { const: 1 },
                        "vinculacionMancomunada": {
                            title: "¿Solicitud de vinculación mancomunada?",
                            type: "boolean",
                            oneOf: [
                                { title: "Si", const: true },
                                { title: "No", const: false },
                            ],
                        },
                    },
                    // required: ["vinculacionMancomunada"],
                    dependencies: {
                        "vinculacionMancomunada": {
                            oneOf: [
                                {
                                    properties: {
                                        "vinculacionMancomunada": {
                                            const: false,
                                        },
                                        "kycPersonalClientSchema": kycPersonalClientSchema,
                                        "clientSchema": clientSchema,
                                        "clientAditionalInfoSchema": clientAditionalInfoSchema,
                                    }
                                },
                                {
                                    properties: {
                                        "vinculacionMancomunada": {
                                            const: true,
                                        },
                                        "kycPersonalClientSchema": kycPersonalClientSchema,
                                        "clientSchema": clientSchema,
                                        "clientAditionalInfoSchema": clientAditionalInfoSchema,
                                    }
                                }
                            ]
                        }
                    }
                },
                {
                    properties: {
                        "clientTypeId": { const: 4 },
                        "kycPersonalClientSchema": kycPersonalClientSchema,
                        "clientSchema": smbCedulaSchema,
                        "clientAditionalInfoSchema": clientAditionalInfoSchema,
                    },
                }
            ]
        }
    }
};



form.uiSchema = {
    "preferredBranchId": { "ui:field": "LookupFormField", "ui:readonly": true },
    "clientTypeId": {
        "ui:readonly": true,
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "vinculacionMancomunada": {
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "kycPersonalClientSchema": {
        "classNames": "smallThreeRows",
        "ui:order": [
            "institucionVincularse",
            "productosBanco",
            "productosPuesto",
            "productosSAFI",
            "savingsAccountSchema",
            "cuentaAhorroBasica",
            "soloMantieneEstaCuenta",
            "excepcionCuentaAhorro",
            "financialCertSchema",
            "inversionesSchema",
            "safiSchema",
            "prestamos",
            "solicitudPrestamoSchema",
            "*",
        ],
        "institucionVincularse": {
            "classNames": "from-col-1 to-col-4",
            "ui:widget": "checkboxes",
            "ui:options": { inline: true },
        },
        "cuentaAhorroBasica": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "excepcionCuentaAhorro": {
            "classNames": "from-col-1",
        },
        "savingsAccountSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "depositoEstimadoRD": { "classNames": "from-col-1 title-2-lines" },
            "origenFondosRD": { "classNames": "title-2-lines" },
            "depositoEstimadoUSD": { "classNames": "from-col-1 title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosRDOtro": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" }
        },
        "financialCertSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "soloMantieneEstaCuenta": {
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "productosBanco": {
            "classNames": "from-col-1",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": {
                inline: false
            },
        },
        "productosPuesto": {
            "classNames": "from-col-2",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "productosSAFI": {
            "classNames": "from-col-3",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },

        "inversionesSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio",
            ],
            "inversionEstimadaUSD": { "classNames": "title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" },
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }

        },
        "safiSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "*",
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio"
            ],
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "prestamos": {
            "classNames": "smallTwoRows from-col-1 to-col-4",
        },
        "solicitudPrestamoSchema": {
            "classNames": "from-col-1 to-col-4",
            "facilidadesSolicitadas": {
                "ui:order": [
                    "*",
                    "incluyeGarantia",
                    "incluyeGarantiaSolidaria",
                    "comentarios",
                    "descGarantias"
                ],
                "descripcionFacilidades": {
                    "ui:options": {
                        "orderable": false,
                    }
                },
                "proposito": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantia": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "comentarios": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantiaSolidaria": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "descGarantias":{
                    "descGarantia":{
                        "ui:widget": "textarea",
                        "ui:options": {
                            rows: 3
                        },
                    },
                    "tipoDeGarantia":{
                        "ui:widget": "checkboxes",
                    },
                    "contratoElaboradoPor":{
                        "ui:widget": "radio"
                    },
                    "ui:order": [
                        "tipoDeGarantia",
                        "contratoElaboradoPor",
                        "*"
                    ],
                },
            },
        },
    },
    "clientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "*",
            "tipoIdentificacionPersonal",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionPersonal": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "buscarDataPersonal":{
            "ui:field": "FetchClientInfoButton",
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "secondClientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "*",
            "tipoIdentificacionPersonal",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionPersonal": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "buscarDataPersonal":{
            "ui:field": "FetchClientInfoButton",
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "clientAditionalInfoSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "fechaNacimiento",
            "lugarNacimiento",
            "nacionalidad",
            "nacionalidadOtroPais",
            "indicarPaises",
            "*",
            "ocupacion",
            "ocupacionOtro",
            "inscritoCedulaAnteDGII",
            "otraFuenteIngresosFormales",
            "ingresosMensuales",
            "sujetoObligadoLey155",
            "tipoSujetoObligado",
            "personaExpuestaPoliticamente",
            "parentescoPersonaExpuestaPoliticamente",
            "beneficiariosDeTransaccion",
            "nombreBeneficiario",
            "apellidoBeneficiario",
            "idBeneficiario",
            "vinculadoJMMB"
        ],
        "nacionalidadOtroPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenteRD": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenciaFisicaUSA": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estatusMigratorio": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "ingresosActividadesFormales": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "otraFuenteIngresosFormales": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tipoSujetoObligado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "personaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "parentescoPersonaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "beneficiariosDeTransaccion": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "vinculadoJMMB": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "secondClientAditionalInfoSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "lugarNacimiento",
            "nacionalidad",
            "nacionalidadOtroPais",
            "indicarPaises",
            "*",
            "ocupacion",
            "ocupacionOtro",
            "inscritoCedulaAnteDGII",
            "otraFuenteIngresosFormales",
            "ingresosMensuales",
            "sujetoObligadoLey155",
            "tipoSujetoObligado",
            "personaExpuestaPoliticamente",
            "parentescoPersonaExpuestaPoliticamente",
            "beneficiariosDeTransaccion",
            "vinculadoJMMB"
        ],
        "nacionalidadOtroPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenteRD": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenciaFisicaUSA": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estatusMigratorio": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "ingresosActividadesFormales": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "otraFuenteIngresosFormales": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tipoSujetoObligado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "personaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "parentescoPersonaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "beneficiariosDeTransaccion": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "vinculadoJMMB": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
}

form.objectMap = {
    "clients[0].retailClientBasicInfo.hasBasicSaving": "kycPersonalClientSchema.cuentaAhorroBasica",
    "clients[0].retailClientBasicInfo.onlyMaintainsThisAccount": "kycPersonalClientSchema.soloMantieneEstaCuenta",
    "clients[0].retailClientBasicInfo.savingsException": "kycPersonalClientSchema.excepcionCuentaAhorro",

    // Puesto

    "caseInvestmentInfo.usdAmountPuesto": "kycPersonalClientSchema.inversionesSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginPuesto": "kycPersonalClientSchema.inversionesSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherPuesto": "kycPersonalClientSchema.inversionesSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountPuesto": "kycPersonalClientSchema.inversionesSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginPuesto": "kycPersonalClientSchema.inversionesSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherPuesto": "kycPersonalClientSchema.inversionesSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRatePuesto": "kycPersonalClientSchema.inversionesSchema.tasaCambio",

    // Certificado Financiero

    "caseInvestmentInfo.rdsAmountCertificate": "kycPersonalClientSchema.financialCertSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginCertificate": "kycPersonalClientSchema.financialCertSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherCertificate": "kycPersonalClientSchema.financialCertSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateCertificate": "kycPersonalClientSchema.financialCertSchema.tasaCambio",

    // SAFI

    "caseInvestmentInfo.usdAmountSAFI": "kycPersonalClientSchema.safiSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginSAFI": "kycPersonalClientSchema.safiSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherSAFI": "kycPersonalClientSchema.safiSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountSAFI": "kycPersonalClientSchema.safiSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginSAFI": "kycPersonalClientSchema.safiSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherSAFI": "kycPersonalClientSchema.safiSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateSAFI": "kycPersonalClientSchema.safiSchema.tasaCambio",

    // Cuenta Ahorro

    "clients[0].retailClientBasicInfo.rdsAmountSavingsAccount": "kycPersonalClientSchema.savingsAccountSchema.depositoEstimadoRD",
    "clients[0].retailClientBasicInfo.rdsFundsOriginSavingsAccount": "kycPersonalClientSchema.savingsAccountSchema.origenFondosRD",
    "clients[0].retailClientBasicInfo.rdsFundsOriginOtherSavingsAccount": "kycPersonalClientSchema.savingsAccountSchema.origenFondosRDOtro",

    "clients[0].retailClientBasicInfo.usdAmountSavingsAccount": "kycPersonalClientSchema.savingsAccountSchema.depositoEstimadoUSD",
    "clients[0].retailClientBasicInfo.usdFundsOriginSavingsAccount": "kycPersonalClientSchema.savingsAccountSchema.origenFondosUSD",
    "clients[0].retailClientBasicInfo.usdFundsOriginOtherSavingsAccount": "kycPersonalClientSchema.savingsAccountSchema.origenFondosUSDOtro",

    "clients[0].retailClientBasicInfo.rdsSelection": "kycPersonalClientSchema.savingsAccountSchema.rdCurrency",
    "clients[0].retailClientBasicInfo.usdSelection": "kycPersonalClientSchema.savingsAccountSchema.usCurrency",
    //
    "clients[0].retailClientLoanInfo.purpose": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.proposito",
    "caseLoanInfo.loanAmount": "kycPersonalClientSchema.prestamos.montoPrestamo",
    "caseLoanInfo.loanDestination": "kycPersonalClientSchema.prestamos.destinoFondos",
    "caseLoanInfo.includesCollateral": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.incluyeGarantia",
    "caseLoanInfo.comments": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.comentarios",

    "caseLoanInfo.includesJointGuarrantee": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.incluyeGarantiaSolidaria",

    "clients[0].retailClientLoanInfo.warrantyNumber": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.noDeGarantia",
    "clients[0].retailClientLoanInfo.numAssociatedFacilities": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.noFacilidadAsociada",
    "clients[0].retailClientLoanInfo.warrantyDescription": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.descGarantia",
    "clients[0].retailClientLoanInfo.value": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.valGarantia",

    "clients[0].retailClientLoanInfo.selectedWarranties": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.tipoDeGarantia",
    "clients[0].retailClientLoanInfo.contractDrafter": "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.contratoElaboradoPor",

    "clients[0].retailClientLoanInfo.loanFacilities": {
        array: "kycPersonalClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descripcionFacilidades",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCanceled": "seraCancelado"
        }
    },
    "isJointly": "vinculacionMancomunada",
    "productInstitutions": "kycPersonalClientSchema.institucionVincularse",
    "productIds.1": "kycPersonalClientSchema.productosBanco",
    "productIds.2": "kycPersonalClientSchema.productosPuesto",
    "productIds.3": "kycPersonalClientSchema.productosSAFI",
    "clients[0].clientTypeId": "clientTypeId",
    "clients[0].preferredBranchId": "preferredBranchId",
    "clients[0].retailClientBasicInfo.firstName": "clientSchema.nombre",
    "clients[0].retailClientBasicInfo.secondName": "clientSchema.segundoNombre",
    "clients[0].retailClientBasicInfo.lastName": "clientSchema.apellidos",
    "clients[0].retailClientBasicInfo.email": "clientSchema.email",
    "clients[0].retailClientBasicInfo.identificationType": "clientSchema.tipoIdentificacionPersonal",
    "clients[0].retailClientBasicInfo.identificationNumber": "clientSchema.numIdentificacionCedula",
    "clients[0].retailClientBasicInfo.passportNumber": "clientSchema.numIdentificacionPasaporte",
    "clients[0].retailClientBasicInfo.foreignIdNumber": "clientSchema.numIdentificacionIdExtranjero",
    "clients[0].retailClientBasicInfo.subscribedAgainstDGII": "clientAditionalInfoSchema.inscritoCedulaAnteDGII",
    "clients[0].retailClientBasicInfo.sex": "clientSchema.sexo",
    "clients[0].retailClientBasicInfo.civilStatus": "clientSchema.estadoCivil",
    "clients[0].retailClientBasicInfo.phone": "clientSchema.telefono",
    "clients[0].retailClientBasicInfo.phoneMobile": "clientSchema.celular",
    "clients[0].retailClientBasicInfo.phoneOther": "clientSchema.otro",
    "clients[0].retailClientBasicInfo.dateOfBirth": "clientAditionalInfoSchema.fechaNacimiento",
    "clients[0].retailClientBasicInfo.countryOfBirth": "clientAditionalInfoSchema.lugarNacimiento",
    "clients[0].retailClientBasicInfo.nationality": "clientAditionalInfoSchema.nacionalidad",
    "clients[0].retailClientBasicInfo.address": "clientAditionalInfoSchema.direccionResidencia",
    "clients[0].retailClientBasicInfo.addressSector": "clientAditionalInfoSchema.sector",
    "clients[0].retailClientBasicInfo.city": "clientAditionalInfoSchema.ciudad",
    "clients[0].retailClientBasicInfo.country": "clientAditionalInfoSchema.pais",
    "clients[0].retailClientBasicInfo.residentOfDominicanRepublic": "clientAditionalInfoSchema.residenteRD",
    "clients[0].retailClientBasicInfo.isAmericanResident": "clientAditionalInfoSchema.residenciaFisicaUSA",
    "clients[0].retailClientFatcaInfo.unitedStatesImmigrationStatus": "clientAditionalInfoSchema.estatusMigratorio",
    "clients[0].retailClientBasicInfo.profession": "clientAditionalInfoSchema.profesion",
    "clients[0].retailClientBasicInfo.occupation": "clientAditionalInfoSchema.ocupacion",
    "clients[0].retailClientBasicInfo.hasOtherSourcesOfIncome": "clientAditionalInfoSchema.otraFuenteIngresosFormales",
    "clients[0].retailClientBasicInfo.monthlyIncome": "clientAditionalInfoSchema.ingresosMensuales",
    "clients[0].retailClientBasicInfo.isSubjectToLaw15517": "clientAditionalInfoSchema.sujetoObligadoLey155",
    "clients[0].retailClientBasicInfo.obligationType": "clientAditionalInfoSchema.tipoSujetoObligado",
    "clients[0].retailClientBasicInfo.hasBeneficiaries": "clientAditionalInfoSchema.beneficiariosDeTransaccion",
    "clients[0].retailClientBasicInfo.beneficiaryName": "clientAditionalInfoSchema.nombreBeneficiario",
    "clients[0].retailClientBasicInfo.beneficiaryLastName": "clientAditionalInfoSchema.apellidoBeneficiario",
    "clients[0].retailClientBasicInfo.beneficiaryId": "clientAditionalInfoSchema.idBeneficiario",
    "clients[0].retailClientBasicInfo.isRelatedToFranchise": "clientAditionalInfoSchema.vinculadoJMMB",
    "clients[0].retailClientBasicInfo.comments": "clientSchema.comentarios",
    "clients[0].retailClientFatcaInfo.hasOtherNationality": "clientAditionalInfoSchema.nacionalidadOtroPais",
    "clients[0].retailClientFatcaInfo.otherNationalities": "clientAditionalInfoSchema.indicarPaises",
    "clients[0].retailClientBasicInfo.nationalGeographicZone": "clientAditionalInfoSchema.zonaGeograficaNacional",
    "clients[0].retailClientBasicInfo.otherOccupation": "clientAditionalInfoSchema.ocupacionOtro",
    "clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposure": "clientAditionalInfoSchema.personaExpuestaPoliticamente",
    "clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposureRelative": "clientAditionalInfoSchema.parentescoPersonaExpuestaPoliticamente",

}


export default form;